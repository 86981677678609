import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import "./appointment.css";
import { getCalendlyAuthURL } from '../../api/calendlyAuth'

function Appointment() {

	async function handleConnectWithCalendly() { // @amaechi figure out why this is not working
		const authURL = getCalendlyAuthURL();
		window.location.href = authURL;
	}

	return (
		<div className="appointment">
			<div class="appointmentWrapper">
				<h4 style={{ fontWeight: "bold" }}>Availability</h4>
				{
					localStorage.getItem("calendlyAccessToken") ? (
						<></>
					) : (
						<button onClick={handleConnectWithCalendly}>Connect with Calendly</button>
					)
				}
				<Row className="tableTop">
					<Col>Day</Col>
					<Col>Open Time</Col>
					<Col>Close Time</Col>
				</Row>
				<Row className="tableBottom">
					<Col>Appointment Not Found</Col>
				</Row>
			</div>
		</div>
	);
}

export default Appointment;
