import { FETCH_EVENTS, CREATE, DELETE, CHECKOUT } from "../constants/actionTypes";

export default (events = [], action) => {
  switch (action.type) {
    case FETCH_EVENTS:
      return action.payload;
    case CREATE:
      return [...events, action.payload];
    case DELETE:
      return events.filter((event) => event._id !== action.payload);
    case CHECKOUT:
      return action.payload;
    default:
      return events;
  }
};
