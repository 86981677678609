import React, { useEffect, useState } from "react";
import "./profile.css";
import * as RiIcon from "react-icons/ri";
import * as BsIcon from "react-icons/bs";
import logo from "../../images/logo.jpg"
import logo2 from "../../images/logo2.jpg"
import {getHealer, getProfileImage, getProfileBackground, updateProfileImage, updateProfileBackground} from '../../api/index.js'

function ProfileTop() {

	const [username, setUsername] = useState((localStorage.getItem("username")));
	const [isHealer, setIsHealer] = useState(false);

	useEffect(() => {
		const fetchHealerStatus = async () => {
			const username = localStorage.getItem("username");
			const healerStatus = await getHealer(username);
			if (healerStatus) {
				setIsHealer(true);
			} else {
				setIsHealer(false);
			}
		}
		const fetchProfilePicture = async () => {
			const username = localStorage.getItem("username");
			const profilePicture = await getProfileImage(username);
			if (profilePicture) {
				// console.log("profilePicture: ", profilePicture)
				setImage(profilePicture.profileImage);
			} else {
				setImage(logo);
			}
		}
		const fetchProfileBackground = async () => {
			const username = localStorage.getItem("username");
			const profilePicture = await getProfileBackground(username);
			if (profilePicture) {
				// console.log("profilePicture: ", profilePicture)
				setImage2(profilePicture.profileImage);
			} else {
				setImage2(logo);
			}
		}
		fetchProfileBackground()
		fetchProfilePicture()
		fetchHealerStatus();
	}, []);

	const [image, setImage] = useState(null);

	const [image2, setImage2] = useState(null);

	const handleFileChange = async (event) => {
		setImage(URL.createObjectURL(event.target.files[0]));
		await updateProfileImage(username, event.target.files[0])

	};
	const handleFileChange2 = async (event) => {
		setImage2(URL.createObjectURL(event.target.files[0]));
		await updateProfileBackground(username, event.target.files[0])
	};

	const handleClick = () => {
		document.getElementById("fileInput").click();
	};
	const handleClick2 = () => {
		document.getElementById("fileInput2").click();
	};

	return (
		<div style={{ paddingBottom: "30px" }} className="profileRight">
			<div className="profileRightTop">
				<div className="profileCover">
					<img
						className="profileCoverImg"
						src={image2 || logo}
						alt=""
					/>

					<div className="profileCoverImg2" onClick={handleClick2}>
						<input
							style={{ display: "none" }}
							id="fileInput2"
							type="file"
							accept="image/*"
							onChange={handleFileChange2}
						/>

						{/* <RiIcon.RiImageEditFill className="profileCoverImg3" /> */}
					</div>
					<img
						className="profileUserImg"
						src={image || logo2}
						alt=""
						style={{ backgroundImage: `url(${image})` }}
					/>

					<div className="profileUserImg2" onClick={handleClick}>
						{/* <RiIcon.RiImageEditFill className="profileUserImg3" /> */}
						<input
							style={{ display: "none" }}
							id="fileInput"
							type="file"
							accept="image/*"
							onChange={handleFileChange}
						/>
					</div>
				</div>
				<br />
				<br/>
				<div className="profileInfo">
					<h4 className="profileInfoName">
						{username}{" "}
						{(isHealer || username === "tech" || username === "trishkane" || username === "TrishKane") && (
							<span>
								<BsIcon.BsPatchCheckFill
									style={{
										color: "#af6ffb",
										cursor: "default",
										marginLeft: "3px",
										position: "relative",
										top: "-2px",
									}}
								/>
							</span>
						)}
					</h4>
				</div>
			</div>
		</div>
	);
}

export default ProfileTop;
