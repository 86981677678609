import React from 'react';
import logo from '../../images/logo.jpg';

const User = ({ user }) => {
  return (
    <div className="user-container">
      <img className="user-avatar" src={user.profileImage ||logo } alt="User Avatar" />
      {/* <span className="user-username">{user.username}</span> */}
    </div>
  );
};


export default User;
