import React, { useState, useEffect } from "react"
import { Button } from "react-bootstrap";
import * as IoIcon from "react-icons/io";
import * as AiIcon from "react-icons/ai";
import "./post.css";
import { useDispatch } from "react-redux";
import { deletePost, likePost, newComment } from "../../actions/posts";
import moment from "moment";
import { Link } from 'react-router-dom';

/**
 * Component skeleton for each Post
 * Contains all characteristics needed to create a post
 * @returns
 */
function Post({ post }) {
  const dispatch = useDispatch();

  const [likes, setLikes] = useState(post.likes);
  const [isLiked, setIsLiked] = useState(false);
  const [comments, setComments] = useState(post.comments);
  const [comment, setComment] = useState('');

  const user = localStorage.getItem("username");

  useEffect(() => {
    setLikes(post.likes);
  }, []);

  const handleClickUser = (username) => {
    window.location.replace(`/user/${username}`);
  };

  const postComment = (e) => {
    e.preventDefault();

    const finalComment = `${user}: ${comment}`;

    dispatch(newComment(finalComment, post._id));

    setComment('')
    window.location.reload();
  }

  return (
    <div className="post">
      <div className="postWrapper">
        <div className="postTop">
          <div className="postTopLeft">
            {/* <img
							className="postProfileImg"
							src={Users.filter((u) => u.id === post?.userId)[0].photo}
							alt=""
						/> */}
            <span className="postUsername">
              <Link to={`/user/${post.username}`}>{post.username}</Link>
            </span>
            <br />
            <span className="postDate">
              {post.date ? moment(post.date)?.fromNow() : "a long time ago"}
            </span>
          </div>
          {post.username === localStorage.getItem("username") && (
            <div className="postTopRight">
              <Button onClick={() => dispatch(deletePost(post._id))}>
                <AiIcon.AiFillDelete style={{ fontSize: "25px" }} />
              </Button>
            </div>
          )}
        </div>
        <div className="postCenter">
          <span className="postText">{post?.desc}</span>
          <img className="postImg" src={post.photo} alt="" />{" "}
        </div>
        {post.tags.length > 0 &&
          post.tags[0] !== "" &&
          post.tags[0] !== " " && (
            <span className="postDate">
              {post.tags.map((tag) => (
                <span key={tag}> #{tag} </span>
              ))}
            </span>
          )}
        <hr />
        <div className="postBottom">
          <div className="postBottomLeft">
            {isLiked ? (
              <AiIcon.AiFillLike
                className="likeIcon"
                onClick={() =>
                  dispatch(likePost(post._id))
                    .then(setIsLiked(false))
                    .then(setLikes(likes - 1))
                }
              />
            ) : (
              <AiIcon.AiOutlineLike
                className="likeIcon"
                onClick={() =>
                  dispatch(likePost(post._id))
                    .then(setIsLiked(true))
                    .then(setLikes(likes + 1))
                }
              />
            )}
            {likes}
            <IoIcon.IoIosShareAlt className="shareIcon" />
            <span className="postLikeCounter">Share</span>
          </div>
          <div className="postBottomRight">
            <span className="postCommentText">{comments.length} comments</span>
          </div>
        </div>
        <hr />
        <div>
          <div className="postTopLeft">
            {/* <img
							className="postProfileImg"
							src="https://via.placeholder.com/150"
							alt=""
						/> */}
            <input
              placeholder="Leave a comment..."
              className="commentInput"
              onChange={(event) => setComment(event.target.value)}
              value={comment}
            />
            <span>
              <Button
                style={{
                  opacity: comment.length > 0 ? 1 : 0,
                  transition: "opacity 0.5s",
                }}
                className="home-btn"
                id="btn-purple"
                onClick={postComment}
              >
                Post Comment
              </Button>
            </span>
          </div>
        </div>
        <hr />
        <div>
          {comments.map((p) => {
            return (
              <>
                <div className="postTopLeft">
                  <Link to={`/user/${p?.username}`}>{p?.username}</Link>
                </div>
                <div style={{ margin: "0px 0px 0px 0px" }}>
                  <span className="comment">{p?.comment}</span>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Post;
