import axios from 'axios';

const api = axios.create({
	baseURL: process.env.REACT_APP_SERVER_DOMAIN || "http://localhost:8080",
});

api.interceptors.request.use((req) => {
	if (localStorage.getItem('token')) {
		req.headers.Authorization = localStorage.getItem('token');
	}
	return req;
})

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const REDIRECT_URI = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI);

export function getCalendlyAuthURL() {
	return `https://auth.calendly.com/oauth/authorize?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}`
}

export async function exchangeAuthorizationCodeForAccessToken(authorizationCode) {
	try {
		const response = await api.post('/api/exchange-token', {
			authorizationCode
		});
		console.log('response: ',response);
		const accessToken = response.data.accessToken;
		return accessToken;
	} catch (error) {
		console.error('Error exchanging authorization code:', error);
		throw error;
	}
}