import React, {useRef} from "react";
import { Button } from "react-bootstrap";
import "./share.css";
import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io5";

/**
 * Component to create new post
 */
function Share({ postData, setPostData, handlePostChange }) {
  const inputRef = useRef();

  function handleUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setPostData({ ...postData, uploadedFile: { file, base64: URL.createObjectURL(file) } });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  return (
    <div className="share">
      <div className="shareWrapper">
        <div className="shareTop">
          <img
            className="shareProfileImg"
            src="https://via.placeholder.com/150" // @amaechi: Load image from user
            alt=""
          />
          <input
            placeholder="What's in your mind?"
            className="shareInput"
            value={postData.desc}
            onChange={(event) =>
              setPostData({ ...postData, desc: event.target.value, username: localStorage.getItem("username") })
            }
          />
        </div>
        <hr className="shareHr" />
        <div className="shareBottom">
          <div className="shareOptions">
            <div className="shareOption">
              <FaIcons.FaPhotoVideo
                className="shareIcons"
                onClick={() => inputRef.current.click()}
              />
              <input
                ref={inputRef}
                type="file"
                accept="image/*,video/*"
                onChange={handleUpload}
                style={{ display: "none" }}
              />
            </div>
            <div className="shareOption">
              <FaIcons.FaHashtag className="shareIcons" />
              <input
                className="Tags"
                value={postData.tags}
                onChange={(event) =>
                  setPostData({ ...postData, tags: event.target.value })
                }
              />
            </div>
          </div>
          <Button
            style={{ marginRight: "20px" }}
            className="home-btn"
            id="btn-purple"
            onClick={handlePostChange}
          >
            Post
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Share;
