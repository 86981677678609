import React, { useEffect, useState } from "react";
import axios from "axios";
import { LinkContainer } from "react-router-bootstrap";
import decode from "jwt-decode";
import { Container, Button, Modal } from "react-bootstrap";
import { loginUser } from "../api/index";
import loader from "../images/EllipsisLoader.gif";

function Login() {

	useEffect(() => {
		const token = localStorage.getItem("token")
		if (token) {
			const decodedToken = decode(token)
			if (decodedToken.exp * 1000 < Date.now()) {
				localStorage.clear();
			} else {
				window.location.replace("/profile");
			}
		}
	})

	const [loginData, setLoginData] = useState({
		username: "",
		password: "",
	});

	const [errors, setErrors] = useState({
		username: false,
		password: false,
	});

	const [isLoading, setIsLoading] = useState(false);
	const [loginStatus, setLoginStatus] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);

	const toggleModal = () => setIsModalOpen(!isModalOpen);

	const handleSubmit = (event) => {
		event.preventDefault();

		// Make an HTTP POST request to the server-side route for handling user registration
		// Check each field and update the error state accordingly
		const newErrors = {};
		if (loginData.username === "") {
			newErrors.username = true;
		}
		if (loginData.password === "") {
			newErrors.password = true;
		}
		setErrors(newErrors);

		// Check if there are any errors
		if (Object.values(newErrors).some((error) => error)) {
			return;
		}

		setIsLoading(true);

		loginUser(loginData).then((res) => {
			setIsLoading(false);
			if (res.status === 200 || res.status === 201) {
				localStorage.setItem("token", res.token);
				localStorage.setItem("username", res.username);
				setLoginStatus("Successful login!");
				window.location.replace("/profile"); 
			} else if (res.status === 404) {
				setLoginStatus("Missing username or password."); 
				setIsModalOpen(true);
				setTimeout(() => setIsModalOpen(false), 5000);
			} else {
				setLoginStatus("Invalid username or password."); 
				setIsModalOpen(true);
				setTimeout(() => setIsModalOpen(false), 5000);
			}
		});
	};

	return (
		<Container
			fluid
			style={{
				margin: "auto",
				textAlign: "center",
				width: "85vw",
				position: "relative",
				top: "100px",
			}}
		>
			<h2 style={{ color: "white", paddingBottom: "10px" }}>Login</h2>
			<p style={{ color: "white", fontWeight: "light" }}>
				Need a Healerverse account?{" "}
				<a style={{ color: "#aa00ff" }} href="/register">
					Create an account
				</a>
			</p>

			<div
				style={{ border: errors.username ? "1px solid #fd7862" : "" }}
				className="formbarMain"
			>
				<input
					placeholder="Username"
					type="text"
					name="username"
					value={loginData.username}
					className="formInputMain"
					onChange={(e) => {
						setLoginData({ ...loginData, username: e.target.value });
						setErrors((prevErrors) => ({ ...prevErrors, username: false }));
					}}
				/>
			</div>

			<br />

			<div
				style={{ border: errors.password ? "1px solid #fd7862" : "" }}
				className="formbarMain"
			>
				<input
					placeholder="Password"
					type="password"
					name="password"
					value={loginData.password}
					className="formInputMain"
					onChange={(e) => {
						setLoginData({ ...loginData, password: e.target.value });
						setErrors((prevErrors) => ({ ...prevErrors, password: false }));
					}}
				/>
			</div>

			<br />

			<p
				className="pb-4"
				style={{ fontSize: "10px", margin: "auto", textAlign: "center" }}
			>
				{/* <a style={{ color: "#aa00ff" }} href="/login">
					Forgot Password?
				</a> */}
			</p>
			<br />

			<LinkContainer style={{ width: "10em" }} to="/profile">
				<Button onClick={handleSubmit} className="home-btn" id="btn-purple">
					{isLoading ? (
						<img style={{ width: "20px" }} src={loader} alt="Loading..." />
					) : (
						"Login"
					)}
				</Button>
			</LinkContainer>
			<Modal
				show={isModalOpen}
				onHide={toggleModal}
				animation={true}
				className="my-modal"
				centered
			>
				<Modal.Body
					style={{
						backgroundColor: "#454545",
						color: "white",
					}}
				>
					<h2>Login Failed</h2>
					<p>Please check your details and try again.</p>
				</Modal.Body>
			</Modal>
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
		</Container>
	);
}

export default Login;
