import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import "../Events/event.css";
import Create from "./Create";
import WorkshopCard from "./WorkshopCard";

function Workshop() {
	const [myWorkshops, setMyWorkshops] = useState([]);
	const [activeItemId, setActiveItemId] = useState("myWorkshops");

	const handleClick = (e) => {
		setActiveItemId(e.target.id);
	};

	const PageDisplay = () => {
		if (activeItemId === "myWorkshops") {
			return (
				<>
					<br />
					{myWorkshops.length === 0 ? (
						<h4>No Workshops</h4>
					) : (
						<Row>
							{myWorkshops.map((w) => {
								return (
									<Col lg={6} md={4} sm={6}>
										<WorkshopCard
											key={w.id}
											pic={w.workshopImg}
											title={w.workshopName}
											date={w.workshopDateTime}
											link={w.workshopUrl}
										/>
									</Col>
								);
							})}
						</Row>
					)}
					<br />
				</>
			);
		} else if (activeItemId === "bookedWorkshops") {
			return (
				<>
					<br />
					<h4>No Workshops</h4>
					<br />
				</>
			);
		} else {
			return (
				<Create myWorkshops={myWorkshops} setMyWorkshops={setMyWorkshops} />
			);
		}
	};
	return (
		<div className="event">
			<div className="eventWrapper">
				<Row>
					<Col>
						<p id="myWorkshops" onClick={handleClick} className="eventMenu">
							My Workshops
						</p>
					</Col>
					<Col>
						<p id="bookedWorkshops" onClick={handleClick} className="eventMenu">
							My Booked Workshops
						</p>
					</Col>
					<Col style={{ margin: "auto" }}>
						<Button
							className="home-btn"
							id="btn-purple"
							style={{ margin: "auto" }}
							onClick={handleClick}
						>
							Create Workshop
						</Button>
					</Col>
				</Row>
				<div>{PageDisplay()}</div>
			</div>
		</div>
	);
}

export default Workshop;
