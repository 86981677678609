import React, { useState, useEffect } from "react";
// import { Posts } from "../../dummyData"; // TODO: this needs to access posts fromd database
import Share from "../Share/Share";
import Post from "../Post/Post";
import "./feed.css";
import { useDispatch, useSelector } from "react-redux";
import { createPost } from '../../actions/posts'
/**
 * Component for displaying entire feed
 * Consists of 'Share' which allows user to create new posts
 * @returns Array of 'Post' component to create feed
 */
const Feed = () => { // Pagination so we dont render all posts at once
	//Loads all posts from state
	const posts = useSelector((state) => state.posts);

	const [postData, setPostData] = useState({
		username: '',
		desc: '',
		tags: [''],
		likes: 0,
		comments: [],
		uploadedFile: { file: {}, base64: null}
	});
	const dispatch = useDispatch();

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(postData);
		dispatch(createPost(postData))
		setPostData("");
		window.location.reload();
	};

	return (
		<div className="feed">
			<div className="feedWrapper">
				<Share
					postData={postData}
					setPostData={setPostData}
					handlePostChange={handleSubmit}
				/>
				{
					posts.length > 0 ? (
						posts
							.slice()
							.reverse()
							.map((p) => (
								<Post
									key={p.id}
									post={p}
									comments={p.comments}
									commentLength={p.comments.length}
								/>
							))
					) : (
						<></>
					)
				};
			</div>
		</div>
	);
}

export default Feed;
