import React from "react";
import { Container, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

function Verification() {
	return (
		<Container
			fluid
			style={{
				margin: "auto",
				textAlign: "center",
				width: "85vw",
				position: "relative",
				top: "100px",
			}}
		>
			<h2 style={{ color: "white", paddingBottom: "10px" }}>
				Registration Successful
			</h2>
			<br />
			<p style={{ color: "white", paddingBottom: "10px" }}>
				Please Enter Your Verification Code
			</p>

			<div className="formbarMain">
				<input
					placeholder="Verification Code"
					type="text"
					name="verification"
					className="formInputMain"
				/>
			</div>
			<br />
			<LinkContainer style={{ width: "15em" }} to="/login">
				<Button className="home-btn" id="btn-purple">
					Submit
				</Button>
			</LinkContainer>
		</Container>
	);
}

export default Verification;
