import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm.js";

// Live Key                       pk_live_51McJE2JGR0pSGyMUCT0ichUhHFr6SmOO96j3cGB04Kp8GMeoAwJtXxM8bA4CvxyazPTJ9mAylWDfgdKEOQMdybOu00HhQhE2Ue
// Test Key                       pk_test_51L73HUCRQaIcvSIjv10YWDkiRs3csNG4RpSEAQPosWxxtNt5nCDCMoD8cMrHXMdum3BbBGVkw8huKq8qyLdg4p2e002WUs5Jyv
const publicKey =
	"pk_live_51McJE2JGR0pSGyMUCT0ichUhHFr6SmOO96j3cGB04Kp8GMeoAwJtXxM8bA4CvxyazPTJ9mAylWDfgdKEOQMdybOu00HhQhE2Ue";
const stripeTestPromise = loadStripe(publicKey);

const options = {
	fonts: [
		{
			cssSrc:
				"https://fonts.googleapis.com/css2?family=Lato:wght@100;400;700&display=swap",
		},
	],
};

export default function StripeContainer() {
	return (
		<Elements stripe={stripeTestPromise} options={options}>
			<CheckoutForm />
		</Elements>
	);
}
