import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchEventById } from "../actions/events";
import { loadStripe } from "@stripe/stripe-js";
import { createEventCheckout } from "../actions/events";
import decode from "jwt-decode";
import { bookEvent } from "../api";
import "./event.css";

const EventPage = () => {
    const { id } = useParams();
    const [eventData, setEventData] = useState(null);
    const dispatch = useDispatch();
    const user = localStorage.getItem("username");

    useEffect(() => {
        const getEventData = async () => {
            const data = await dispatch(fetchEventById(id));
            console.log("DATA: ", data);
            setEventData(data);
        };
        const refreshToken = () => {
            const token = localStorage.getItem("token");
            if (!token) {
                window.location.replace("/login");
            } else {
                const decodedToken = decode(token)
                if (decodedToken.exp * 1000 < Date.now()) {
                    localStorage.clear();
                    window.location.replace("/login");
                }
            }
        }

        refreshToken();
        getEventData();

    }, [dispatch, id]);
    // console.log("EVENT DATA: ", eventData);

    /** Stripe Setup */

    // Live Key                       pk_live_51McJE2JGR0pSGyMUCT0ichUhHFr6SmOO96j3cGB04Kp8GMeoAwJtXxM8bA4CvxyazPTJ9mAylWDfgdKEOQMdybOu00HhQhE2Ue
    // Test Key                       pk_test_51L73HUCRQaIcvSIjv10YWDkiRs3csNG4RpSEAQPosWxxtNt5nCDCMoD8cMrHXMdum3BbBGVkw8huKq8qyLdg4p2e002WUs5Jyv
    const stripePromise = loadStripe(
        "pk_test_51L73HUCRQaIcvSIjv10YWDkiRs3csNG4RpSEAQPosWxxtNt5nCDCMoD8cMrHXMdum3BbBGVkw8huKq8qyLdg4p2e002WUs5Jyv"
    );

    const handlePayment = async () => {
        // Wait for Stripe to load
        const stripe = await stripePromise;

        // Create a Checkout Session
        const response = await dispatch(createEventCheckout(id, user))

        // console.log('res',response)
        // Redirect the user to the Stripe Checkout page
        const result = await stripe.redirectToCheckout({
            sessionId: response.id,
        });

        // Handle any errors
        if (result.error) {
            console.error(result.error.message);
        } else {
            await userBookEvent()
        }
    };

    const userBookEvent = async () => {
        const response = await bookEvent(id, user);
        console.log("BOOK EVENT RESPONSE: ", response);
    }

    if (!eventData) {
        return <div>Loading...</div>;
    }
    return (
        <div className="event-container">
            <div className="event-header">
                <h1>{eventData.eventName}</h1>
                <p className="event-date">{eventData.startDate} - {eventData.endDate}</p>
            </div>

            <div className="event-body">
                <div className="event-info">
                    <p>
                        <strong>Created by:</strong> {eventData.createdBy}
                    </p>
                    <p>
                        <strong>Event Category:</strong> {eventData.eventCategory}
                    </p>
                    <p>
                        <strong>Event Location:</strong> {eventData.eventLocation}
                    </p>
                    <p>
                        <strong>Start Date:</strong> {eventData.startDate}
                    </p>
                    <p>
                        <strong>End Date:</strong> {eventData.endDate}
                    </p>
                    <p>
                        <strong>Start Time:</strong> {eventData.startTime} {eventData.timezone}
                    </p>
                    <p>
                        <strong>End Time:</strong> {eventData.endTime} {eventData.timezone}
                    </p>
                    <p>
                        <strong>Number of Attendees:</strong> {eventData.noAttendees}
                    </p>
                    <p>
                        <strong>Event Price:</strong>{" "}

                        {eventData.eventPrice > 0 ? `$${eventData.eventPrice}` : "Free"}
                    </p>
                    <p>
                        <strong>Tags:</strong> {eventData.tags.length > 0 &&
                            eventData.tags[0] !== "" &&
                            eventData.tags[0] !== " " && (
                                <span className="postDate">
                                    {eventData.tags.map((tag) => (
                                        <span key={tag} className="event-tag"> #{tag} </span>
                                    ))}
                                </span>
                            )}
                    </p>
                </div>
                <div className="event-description">
                    <h2>Description</h2>
                    <p>{eventData.EventDescription}</p>
                </div>
            </div>

            <div className="event-footer">
                {eventData.eventPrice > 0 ? <button className="event-payment-btn" onClick={handlePayment}>
                    Pay
                </button> : null}
            </div>
        </div>
    );
};

export default EventPage;
