import axios from 'axios';

const api = axios.create({
	baseURL: process.env.REACT_APP_SERVER_DOMAIN || "http://localhost:8080",
});

api.interceptors.request.use((req) => {
	if (localStorage.getItem('token')) {
		req.headers.Authorization = localStorage.getItem('token');
	}
	return req;
})

/** /API Requests */

/** Auth Functions */
export async function authenticate(username) {
	try {
		return await api.post("/api/auth", { username });
	} catch (error) {
		return { error: "Error authenticating user" };
	}
}

/** Get User Details */
export async function getUser(username) {
	try {
		const { data } = await api.get(`/api/user/${username}`);
		console.log("data: ", data);
		return data;
	} catch (error) {
		return { error: "Error getting user" };
	}
}

/** Get Healer Status */
export async function getHealer(username) {
	try {
		await api.get(`/api/healer:${username}`);
		return true;
	} catch (error) {
		return false;
	}
}

/** Register User */
export async function registerOTP(credentials) {
	try {
		const {
			data: { msg },
			status,
		} = await api.post("/api/register", credentials);

		let { username, email } = credentials;

		// Send OTP Email
		if (status === 200) {
			await api.post("/api/generateRegisterOTP", { username, email });
		}
	} catch (error) {
		return Promise.reject({ error });
	}
}

export async function registerUser(credentials) {
	try {
		const { status } = await api.post("/api/register", credentials);

		console.log("status: ", status)

		if (status === 201 || status === 200) {
			let {
				username,
				// password,
				email,
				// firstName,
				// lastName,
				// healerStatus,
				// dateOfBirth,
				// infoSold,
			} = credentials;

			try {
				await api.post("/api/registerMail", {
					username,
					userEmail: email,
					text: 'Thank you for registering with us.',
					subject: "Registration Successful",
				});
			} catch (error) {
				console.log("error: ", error)
				return Promise.reject({ error });
			}
			// await api.post('')
			return Promise.resolve({ status });
		} else {
			return Promise.resolve({ status });
		}
	} catch (error) {
		return Promise.resolve({ error });
	}
}

/** Login User */
export async function loginUser(credentials) {
	const { username, password } = credentials;

	if (username && password) {
		try {
			const res = await api.post("/api/login", { username, password });
			return Promise.resolve({ username, token: res.data, status: res.status });
		} catch (error) {
			return Promise.resolve({ error });
		}
	}
	return Promise.resolve({ status: 404 });
}

/** Update User */
export async function updateUser(response) {
	try {
		const token = localStorage.getItem("token");
		const data = await api.put("/api/updateUser", response, {
			headers: { Authorization: `Bearer ${token}` },
		});

		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject({ error });
	}
}

/** Update Profile Image */
export async function updateProfileImage(username, file) {
	try {
		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('username', username);
		formData.append('profileImage', file);

		const response = await api.put(`/api/updateProfileImage`, formData, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		});

		return Promise.resolve(response.data);
	} catch (error) {
		return Promise.reject({ error });
	}
}

/** Update Profile Background */
export async function updateProfileBackground(username, file) {
	try {
		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('username', username);
		formData.append('profileBackground', file);

		const response = await api.put(`/api/updateProfileBackground`, formData, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		});

		return Promise.resolve(response.data);
	} catch (error) {
		return Promise.reject({ error });
	}
}

/** Get Profile Image */
export async function getProfileImage(username) {
	try {
		const response = await api.get(`/api/getProfileImage/${username}`);
		return Promise.resolve(response.data);
	} catch (error) {
		return Promise.reject({ error });
	}
}

/** Get Profile Background */
export async function getProfileBackground(username) {
	try {
		const response = await api.get(`/api/getProfileBackground/${username}`);
		return Promise.resolve(response.data);
	} catch (error) {
		return Promise.reject({ error });
	}
}

/** Generate OTP */
export async function generateOTP({ username }) {
	try {
		const {
			data: { code },
			status,
		} = await api.get("/api/generateOTP", { params: { username } });
		if (status === 201) {
			let {
				data: { email },
			} = await getUser({ username });
			let text = `Your OTP is ${code}`;
			await api.post("/api/registerMail", {
				username,
				userEmail: email,
				text,
				subject: "OTP",
			});
		}
		return Promise.resolve(code);
	} catch (error) {
		return Promise.reject({ error });
	}
}

/** Verify OTP */
export async function verifyOTP({ username, code }) {
	try {
		const { data, status } = await api.get("/api/verifyOTP", {
			params: { username, code },
		});
		return { data, status };
	} catch (error) {
		return Promise.reject({ error });
	}
}

/** Reset Password */
export async function resetPassword({ username, password }) {
	try {
		const { data, status } = await api.put("/api/resetPassword", {
			username,
			password,
		});
		return Promise.resolve({ data, status });
	} catch (error) {
		return Promise.reject({ error });
	}
}

/** Process Payment */
export async function payment(username, monthlyPayment) {
	const data = await api.post("/api/payment", {
		username: username,
		isMonthly: monthlyPayment,
	});
	return data;
}

/** Get Social Media Posts */
export async function fetchPosts() {
	return await api.get('/api/getPosts');
}

export const fetchPostsBySearch = async (searchQuery) => {
	return await api.get(`/api/search?searchQuery=${searchQuery.search || 'none'}`);
}

/** Create Social Media Post */
export const createPost = async (newPost) => {
	const formData = new FormData();

	for (const key in newPost) {
		if (newPost.hasOwnProperty(key)) {
			if (key === 'uploadedFile' && newPost[key].file) {
				formData.append(key, newPost[key].file);
			} else {
				formData.append(key, newPost[key]);
			}
		}
	}

	return await api.post('/api/createPost', formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
}

/** Delete Social Media Post */
export const deletePost = async (id) => {
	return await api.delete(`/api/deletePost/${id}`); // handle responses
}

/** Like Post */
export const likePost = async (id) => {
	return await api.put(`/api/${id}/likePost`);
}

/** Comment On Post */
export const newComment = async (value, id) => {
	return await api.post(`/api/${id}/newComment`, { value });
}

/** Create Event */
export const createEvent = async (newEvent) => {
	return await api.post('/api/createEvent', newEvent);
}

/** Create Event Stripe Checkout */
export const createEventCheckout = async (data, user) => {
	return await api.post('/api/createEventCheckout', { data, user });
}

/** Book Event */
export const bookEvent = async (data, user) => {
	return await api.post('/api/bookEvent', { data, user });
}

/** Create Stripe Account */
export async function createUserStripeAccount(user) {
	const token = localStorage.getItem('token');
	try {
		const response = await api.post('/api/createStripeAccount', { username: user }, {
			headers: { Authorization: `Bearer ${token}` },
		});
		console.log(response);
		if (response.data.stripeId) {
			localStorage.setItem('stripeId', response.data.stripeId);
		} else {
			// Redirect the user to the Stripe onboarding URL
			window.location.href = response.data.url;
		}
	} catch (error) {
		console.log('Error res: ', error.response)
		console.error('Error creating Stripe account:', error);
	}
}

/** Verify Stripe Account */
export async function verifyStripeAccount(user) {
	const token = localStorage.getItem('token');
	try {
		const response = await api.post('/api/verifyStripeAccount', { username: user }, {
			headers: { Authorization: `Bearer ${token}` },
		});
		console.log(response);
		if (!response.data.url) {
			localStorage.setItem('stripeId', response.data.stripeId);
			return response
		} else {
			// Redirect the user to the Stripe onboarding URL
			window.location.href = response.data.url;
		}
	} catch (error) {
		console.log('Error res: ', error.response)
		console.error('Error creating Stripe account:', error);
	}
}

/** Get Events */
export const fetchEvents = async () => {
	return await api.get('/api/fetchEvents');
}

/** Get Event By ID */
export const fetchEventById = async (id) => {
	return await api.get(`/api/fetchEventById/${id}`);
}

export const fetchEventByUser = async (username) => {
	return await api.get(`/api/fetchEventByUser/${username}`);
}

export const fetchBookedEventsByUser = async (username) => {
	return await api.get(`/api/fetchBookedEventsByUser/${username}`);
}