import React from "react";
import "./workshop.css";
import { Row, Col } from "react-bootstrap";
import * as IoIcons from "react-icons/io5";

function WorkshopCard({ pic, title, date, link }) {
	return (
		<div className="workshopCard">
			<img className="workshopImg" src={pic} alt="" />

			<div className="workshopBody">
				<h4 style={{ fontWeight: "bold" }}>{title}</h4>
				<Row>
					<Col xs={1}>
						<IoIcons.IoCalendar className="workshopIcon" />
					</Col>
					<Col>{date}</Col>
				</Row>
				<Row>
					<Col xs={1}>
						<IoIcons.IoLink className="workshopIcon" />
					</Col>
					<Col>{link}</Col>
				</Row>
				<Row>
					<Col xs={1}>
						<IoIcons.IoTicket className="workshopIcon" />
					</Col>
					<Col>Ticket Id</Col>
				</Row>
				<br />
				<Row>
					<Col style={{ textAlign: "left" }} xs={6}>
						<p style={{ margin: "auto" }}>
							<span style={{ color: "#aa00ff" }}>Quantity:</span> 1
						</p>
					</Col>
					<Col style={{ textAlign: "right" }} xs={6}>
						<IoIcons.IoDownloadOutline
							style={{ margin: "auto" }}
							className="downloadIcon"
						/>
					</Col>
				</Row>
			</div>
		</div>
	);
}

export default WorkshopCard;
