import React, { useState } from "react";
import TimeDetails from "./TimeDetails.tsx";
import PaymentDetails from "./PaymentDetails";
import AppointmentDetails from "./AppointmentDetails.tsx";
import { Button, Card, Row, Col } from "react-bootstrap";

function AppointmentForm({ setOpenModal, isAppointment }) {
	const [page, setPage] = useState(0);

	const [formData, setFormData] = useState({
		healer: "",
		location: "",
		date: "",
		time: "",
		duration: "",
		cardNum: "",
		expiryDate: "",
		cvc: "",
		name: "",
	});

	const FormTitles = [
		"Who and Where?",
		"When do you want to come?",
		"Enter your payment details.",
	];

	const PageDisplay = () => {
		if (page === 0) {
			return (
				<AppointmentDetails formData={formData} setFormData={setFormData} />
			);
		} else if (page === 1) {
			return <TimeDetails formData={formData} setFormData={setFormData} />;
		} else {
			return <PaymentDetails formData={formData} setFormData={setFormData} />;
		}
	};

	return (
		<Card
			className="home-card text-center flex-fill"
			style={{ padding: "25px", width: "50vw" }}
		>
			<Card.Title>
				{isAppointment ? (
					<h3>Book An Appointment</h3>
				) : (
					<h3>Book A Workshop</h3>
				)}
			</Card.Title>
			<p>{FormTitles[page]}</p>
			<br />
			<div>{PageDisplay()}</div>
			<br />
			<Row
				className="justify-content-center"
				style={{ margin: "auto", width: "60%" }}
			>
				<Col lg={6}>
					<Button
						className="home-btn"
						id="btn-purple"
						style={{ width: "100%" }}
						disabled={page === 0}
						onClick={() => {
							setPage((currPage) => currPage - 1);
						}}
					>
						Previous
					</Button>
				</Col>
				<br />
				<br />
				<Col lg={6}>
					<Button
						className="home-btn"
						id="btn-purple"
						style={{ width: "100%" }}
						onClick={() => {
							if (page === FormTitles.length - 1) {
								alert("FORM SUBMITTED");
								console.log(formData);
							} else {
								setPage((currPage) => currPage + 1);
							}
						}}
						type={page === FormTitles.length - 1 ? "submit" : ""}
					>
						{page === FormTitles.length - 1 ? "Submit" : "Next"}
					</Button>
				</Col>
			</Row>
		</Card>
	);
}

export default AppointmentForm;
