import React, { useState, useEffect } from "react";
import * as IoIcons from "react-icons/io";
import { Row, Col, Button } from "react-bootstrap";
import Sidebar from "../components/Sidebar/Sidebar";

import "./profile2.css";

import Feed from "../components/Feed/Feed";
import ProfileTop from "../components/Profile/ProfileTop";
import Appointment from "../components/Appointments/Appointment";
import EditProfile from "../components/Profile/EditProfile";
import Event from "../components/Events/Event";
import Workshop from "../components/Workshops/Workshop";



export default function Profile2() {
	const [isOpen, setIsOpen] = useState(false);
	const [width, setWidth] = useState(getWindowWidth());
	let col2 = 9;

	const [activeItemId, setActiveItemId] = useState("menuTimeline");

	const handleClick = (e) => {
		setActiveItemId(e.target.id);
	};

	const goToCheckout = () => {
		window.location.href = "/checkout";
	};
	
	function getWindowWidth() {
		const windowWidth = window.innerWidth;
		return windowWidth;
	}

	const PageDisplay = () => {
		if (activeItemId === "menuTimeline") {
			return <Feed />;
		} else if (activeItemId === "menuEvent") {
			return <Event />;
		} else if (activeItemId === "menuWork") {
			return <Workshop />;
		} else if (activeItemId === "menuAppoint") {
			return <Appointment />;
		} else {
			return <EditProfile />;
		}
	};

	useEffect(() => {
		function handleWindowResize() {
			setWidth(getWindowWidth());
		}

		window.addEventListener("resize", handleWindowResize);
		if (width > 992) {
			col2 = 12;
			setIsOpen(true);
		} else {
			col2 = 9;
			setIsOpen(false);
		}

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, [width]);

	return (
		<section
			style={{
				margin: "auto",
				position: "relative",
				top: "-58px",
			}}
		>
			<div
				style={{
					height: "40px",
					width: "40px",
					borderRadius: "0 48% 48% 0",
					backgroundColor: "#aa00ff",
					display: width < 992 ? "block" : "none",
					position: "fixed",
					top: "265px",
					zIndex: "3",
				}}
			>
				{!isOpen ? (
					<IoIcons.IoIosArrowDropright
						style={{
							margin: "7px 0px 0px 5px",
							textAlign: "center",
							color: "white",
							fontSize: "25px",
						}}
						onClick={() => setIsOpen(!isOpen)}
					/>
				) : (
					<IoIcons.IoIosArrowDropleft
						style={{
							margin: "7px 0px 0px 5px",
							textAlign: "center",
							color: "white",
							fontSize: "25px",
						}}
						onClick={() => setIsOpen(!isOpen)}
					/>
				)}
			</div>

			<Row style={{ height: "100%", width: "100vw" }}>
				<Col style={{ padding: "0px 0px 0px 12px" }} lg={0}>
					<Sidebar isOpen={isOpen} />
				</Col>
				<Col style={{ marginLeft: "12px" }} lg={col2}>
					<br />
					<ProfileTop />
					<hr style={{ color: "white" }} />

					<Row style={{ textAlign: "center" }}>
						{/* <Col>
							<p
								className={`profileMenu ${activeItemId === "menuAppoint" ? "clicked" : ""
									}`}
								id="menuAppoint"
								onClick={handleClick}
							>
								Appointments
							</p>
						</Col> */}
						{/* <Col>
							<p
								className={`profileMenu ${activeItemId === "menuWork" ? "clicked" : ""
									}`}
								id="menuWork"
								onClick={handleClick}
							>
								Workshops
							</p>
						</Col> */}
						<Col>
							<p
								className={`profileMenu ${activeItemId === "menuEvent" ? "clicked" : ""
									}`}
								id="menuEvent"
								onClick={handleClick}
							>
								Events
							</p>
						</Col>
						<Col>
							<p
								className={`profileMenu ${activeItemId === "menuTimeline" ? "clicked" : ""
									}`}
								id="menuTimeline"
								onClick={handleClick}
							>
								Timeline
							</p>
						</Col>
					</Row>
					<hr style={{ color: "white" }} />
					<Row>
						<Col lg={9}>{PageDisplay()}</Col>
						<Col style={{ color: "white" }} lg={3}>
							<br />
							<div
								style={{
									backgroundColor: "#242424",
									padding: "20px 0 5px 20px",
									borderRadius: "10px",
								}}
							>
								<p
									id="editProf"
									className="editProfileText"
									onClick={handleClick}
								>
									<span>
										<IoIcons.IoMdCreate />
									</span>{" "}
									Edit Profile
								</p>
							</div>
							<br />
							<Button
								style={{ width: "100%" }}
								className="home-btn"
								id="btn-purple"
								onClick={goToCheckout}
							>
								Become Verified
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</section>
	);
}

