import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import Create from "./Create";
import "./event.css";
import EventCard from "./EventCard";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../images/logo.jpg";
import { fetchEvents } from "../../actions/events";
import { fetchEventByUser, fetchBookedEventsByUser } from "../../api";

function Event() {
	const events = useSelector((state) => state.events);
	const [activeItemId, setActiveItemId] = useState("allEvents");
	const [myEvents, setMyEvents] = useState([]);
	const [bookedEvents, setBookedEvents] = useState([]);
	const user = localStorage.getItem("username");

	useEffect(() => {
		const fetchEvents = async () => {
		  try {
			const response = await fetchEventByUser(user);
			console.log(response);
			if (response && response.data) {
			  setMyEvents(response.data);
			}
		  } catch (error) {
			console.error('Error fetching events:', error);
		  }
		};
		
		const fetchBooked = async () => {
			try {
				const response = await fetchBookedEventsByUser(user);
				console.log(response);
				if (response && response.data) {
					setBookedEvents(response.data);
				}
			} catch (error) {
				console.error('Error fetching events:', error);
			}
		}

		fetchBooked()
		fetchEvents();
	  }, [user]);

	const handleClick = (e) => {
		setActiveItemId(e.target.id);
	};

	const PageDisplay = () => {
		if (activeItemId === "myEvents") {
			// setMyEvents(dispatch(fetchEventsByUser())) // @amaechi
			return (
				<>
					<br />
					{myEvents.length === 0 ? (
						<h4>You Have Created 0 Events</h4>
					) : (
						<Row>
							{myEvents?.map((e) => {
								return (
									<Col lg={6} md={4} sm={6}>
										<EventCard
											key={e._id}
											id={e._id}
											pic={logo}
											title={e.eventName}
											date={e.startDate}
											link={`https://www.5dhealerverse.com/events/${e._id}`}
											price={e.eventPrice}
											location={e.eventLocation}
										/>
									</Col>
								);
							})}
						</Row>
					)}
					<br />
				</>
			);
		} else if (activeItemId === "bookedEvents") {
			return (
				<>
					<br />
					{bookedEvents.length === 0 ? (
						<h4>You Have Booked 0 Events</h4>
					) : (
						<Row>
							{bookedEvents?.map((e) => {
								return (
									<Col lg={6} md={4} sm={6}>
										<EventCard
											key={e._id}
											id={e._id}
											pic={logo}
											title={e.eventName}
											date={e.startDate}
											link={`https://www.5dhealerverse.com/events/${e._id}`}
											price={e.eventPrice}
											location={e.eventLocation}
										/>
									</Col>
								);
							})}
						</Row>
					)}
					<br />
				</>
			);
		} else if (activeItemId === "allEvents") {
			// console.log("EVENTS: ", events)
			return (
				<>
					<br />
					{events.length === 0 ? (
						<h4>0 Active Healeverse Events</h4>
					) : (
						<Row>
							{events?.reverse().map((e) => {
								return (
									<Col lg={6} md={4} sm={6}>
										<EventCard
											key={e._id}
											id={e._id}
											pic={logo}
											title={e.eventName}
											date={e.startDate}
											link={`https://www.5dhealerverse.com/events/${e._id}`}
											price={e.eventPrice}
											location={e.eventLocation}
										/>
									</Col>
								);
							})}
						</Row>
					)}
					<br />
				</>
			);
		} else {
			return <Create myEvents={events} setMyEvents={events} />;
		}
	};

	return (
		<div className="event">
			<div className="eventWrapper">
				<Row>
					<Col>
						<p id="allEvents" onClick={handleClick} className="eventMenu">
							Healerverse Events
						</p>
					</Col>
					<Col>
						<p id="myEvents" onClick={handleClick} className="eventMenu">
							My Events
						</p>
					</Col>
					<Col>
						<p id="bookedEvents" onClick={handleClick} className="eventMenu">
							My Booked Events
						</p>
					</Col>
					<Col style={{ margin: "auto" }}>
						<Button
							className="home-btn"
							id="btn-purple"
							style={{ margin: "auto" }}
							onClick={handleClick}
						>
							Create Event
						</Button>
					</Col>
				</Row>
				<div>{PageDisplay()}</div>
			</div>
		</div>
	);
}

export default Event;
