import React, { useState, useEffect } from "react";
import { NavigationData } from "./NavigationData";
import { Navbar, Container, Nav, Button, Image } from "react-bootstrap";
import "./Navigation.css";
import logo from "../../images/logo.jpg";
import { Link } from "react-router-dom";

function Navigation({ solidBg }) {
  const [background, setBackground] = useState("transparent");

  const [width, setWidth] = useState(getWindowWidth());
  const [toggleClicked, setToggleClicked] = useState(false);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 690;
      if (show) {
        setBackground("black");
      } else {
        if (!toggleClicked) {
          setBackground("transparent");
        }
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [toggleClicked]);

  function getWindowWidth() {
    const windowWidth = window.innerWidth;
    return windowWidth;
  }
  useEffect(() => {
    function handleWindowResize() {
      setWidth(getWindowWidth());
    }

    window.addEventListener("resize", handleWindowResize);
    // // if (width > 992) {
    // // 	setBackground("transparent");
    // // } else {
    // 	// if (toggleClicked) {
    // 		setBackground("black");
    // // 	}
    // // }

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [width, toggleClicked]);

  function handleToggle() {
    setToggleClicked(!toggleClicked);
  }
  return (
    <Container fluid>
      <Navbar
        collapseOnSelect
        className="fixed-top pt-4 pb-3"
        expand="lg"
        variant="dark"
        style={{ backgroundColor: solidBg ? "black" : `${background}` }}
      >
        <Container>
          {/* <Navbar.Brand style={{ paddingLeft: "25px" }} href="/#home"> */}
          <Link to="/#home" style={{ paddingLeft: "25px" }}>
            <Image
              src={logo}
              alt="Logo"
              style={{ width: "100px", height: "100px" }}
            />
          </Link>
          {/* </Navbar.Brand> */}
          <Navbar.Toggle
            // onClick={solidBg ? "" : handleToggle}
            aria-controls="responsive-navbar-nav"
          />
          <Navbar.Collapse
            className="justify-content-end"
            id="responsive-navbar-nav"
          >
            <Nav>
              {/* <Nav.Item>
                <Nav.Link href="/login">
                  {!token ? (
                    <Button className="home-btn" style={{ fontSize: "13px" }}>
                      LOGIN
                    </Button>
                  ) : (
                    <span>
                      Welcome To Healerverse{" "}
                      {localStorage.getItem("username").toUpperCase()}
                    </span>
                  )}
                </Nav.Link>
              </Nav.Item> */}
              {/* <Nav.Item>
                  <Nav.Link href="/checkout">
                    <Button className="home-btn" style={{ fontSize: "13px" }}>
                      BECOME VERIFIED
                    </Button>
                  </Nav.Link>
                </Nav.Item> */}
            </Nav>
            <Nav>
              <Nav.Item>
                <Link to="/about">About Us</Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
}

export default Navigation;
