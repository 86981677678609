import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import TimezonePicker from "react-timezone-select";

function Create({ myWorkshops, setMyWorkshops }) {
	const [formData, setFormData] = useState({});
	const [timeZone, setTimeZone] = useState("");
	const [image, setImage] = useState(null);

	const handleFileChange = (event) => {
		setImage(URL.createObjectURL(event.target.files[0]));
		formData.workshopImg = image;
		setFormData({ ...formData, [event.target.name]: image });
	};

	useEffect(() => {
		formData.workshopImg = image;
	}, [image]);

	const handleChange = (event) => {
		let maxLen = 8;
		if (event.target.name === "srtCode") {
			maxLen = 6;
		}
		if (event.target.name === "srtCode" || event.target.name === "actNum") {
			if (!isNaN(event.target.value) && event.target.value.length <= maxLen) {
				setFormData({ ...formData, [event.target.name]: event.target.value });
			}
		} else {
			setFormData({ ...formData, [event.target.name]: event.target.value });
		}
	};

	const handleTimeZoneChange = (selectedTimeZone) => {
		setTimeZone(selectedTimeZone);

		setFormData({ ...formData, eventTimeZone: selectedTimeZone });
	};

	const handleClick2 = () => {
		document.getElementById("workshopImg").click();
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		setFormData({ ...formData, id: formData.length + 1 });

		if (
			!formData.workshopImg ||
			!formData.workshopName ||
			!formData.workshopUrl ||
			!formData.maxCapacity ||
			!formData.workshopDateTime ||
			!formData.actNum ||
			!formData.srtCode ||
			!formData.WorkshopDescription ||
			!timeZone
		) {
			alert("Please fill out all fields");
			return;
		}
		console.log(formData);
		setMyWorkshops([...myWorkshops, formData]);
		setImage(null);
		setTimeZone("");
		setFormData({
			workshopImg: "",
			workshopName: "",
			workshopUrl: "",
			maxCapacity: "",
			workshopDateTime: "",
			actNum: "",
			srtCode: "",
			WorkshopDescription: "",
		});
	};
	return (
		<div>
			<br />
			<h4>Create New Workshop</h4>
			<br />
			<form onSubmit={handleSubmit}>
				<Row>
					<Col style={{ textAlign: "left" }}>
						<div style={{ position: "relative" }}>
							<img
								className="uploadImg"
								src={image || "https://via.placeholder.com/150"}
								alt="profile"
							/>
							<div className="uploadImg2" onClick={handleClick2}>
								<input
									style={{ display: "none" }}
									id="workshopImg"
									name="workshopImg"
									type="file"
									accept="image/*"
									onChange={handleFileChange}
								/>

								<p
									style={{
										position: "relative",
										textAlign: "center",
										margin: "auto",
										color: "black",
										top: "45%",
									}}
								>
									Upload Photo
								</p>
							</div>
						</div>
					</Col>
				</Row>
				<br />
				<Row>
					<Col>
						<div className="formbarEvent">
							<input
								placeholder="Enter Workshop Name"
								type="text"
								value={formData.workshopName}
								name="workshopName"
								onChange={handleChange}
								className="formInputEvent"
							/>
						</div>
					</Col>
					<Col>
						<Row>
							<Col xs={6}>
								<div className="formbarEvent">
									<input
										placeholder="Enter Event Name"
										type="datetime-local"
										value={formData.workshopDateTime}
										id="workshopDateTime"
										name="workshopDateTime"
										className="formInputEvent"
										onChange={handleChange}
									/>
								</div>
							</Col>
							<Col xs={6}>
								<div className="formbarEvent">
									<TimezonePicker
										id="timeZone"
										name="worshopTimeZone"
										className="formInputEvent timezone"
										placeholder="Time Zone"
										value={timeZone}
										styles={{
											control: (baseStyles, state) => ({
												...baseStyles,
												backgroundColor: "transparent",
												border: "none",
												textAlign: "left",
												boxShadow: state.isFocused
													? "none"
													: baseStyles.boxShadow,
												padding: "5px",
												color: "white",
											}),
											option: (baseStyles) => ({
												...baseStyles,

												boxShadow: "none",
												color: "black",
											}),
											input: (baseStyles) => ({
												...baseStyles,

												color: "white",
											}),
											singleValue: (baseStyles) => ({
												...baseStyles,

												color: "white",
											}),
										}}
										onChange={handleTimeZoneChange}
									/>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>

				<br />
				<Row>
					<Col>
						<div className="formbarEvent">
							<input
								placeholder="Workshop URL"
								type="text"
								value={formData.workshopUrl}
								name="workshopUrl"
								onChange={handleChange}
								className="formInputEvent"
							/>
						</div>
					</Col>
					<Col>
						<div className="formbarEvent">
							<input
								placeholder="Max Capacity"
								type="number"
								value={formData.maxCapacity}
								name="maxCapacity"
								onChange={handleChange}
								className="formInputEvent"
								min="1"
							/>
						</div>
					</Col>
				</Row>
				<br />
				<Row>
					<Col>
						<div className="formbarEvent">
							<input
								placeholder="Account Number"
								type="text"
								name="actNum"
								maxLength={8}
								onChange={handleChange}
								value={formData.actNum}
								onKeyDown={(event) => {
									if (
										event.target.value.length >= 8 &&
										event.key !== "Backspace"
									) {
										event.preventDefault();
									} else if (
										!/\d/.test(event.key) &&
										event.key !== "Backspace"
									) {
										event.preventDefault();
									}
								}}
								className="formInputEvent"
							/>
						</div>
					</Col>
					<Col>
						<div className="formbarEvent">
							<input
								placeholder="Sort Code"
								type="text"
								value={formData.srtCode}
								name="srtCode"
								maxLength={6}
								onChange={handleChange}
								onKeyDown={(event) => {
									if (
										event.target.value.length >= 6 &&
										event.key !== "Backspace"
									) {
										event.preventDefault();
									} else if (
										!/\d/.test(event.key) &&
										event.key !== "Backspace"
									) {
										event.preventDefault();
									}
								}}
								className="formInputEvent"
							/>
						</div>
					</Col>
				</Row>

				<br />
				<Row>
					<Col>
						<textarea
							placeholder="Description Here ..."
							name="WorkshopDescription"
							value={formData.WorkshopDescription}
							onChange={handleChange}
							className="formTextAreaEvent"
						/>
					</Col>
				</Row>
				<br />
				<button type="submit" className="form-submit">
					Create
				</button>
			</form>
		</div>
	);
}

export default Create;
