// src/components/CalendlyCallback.js

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import { exchangeAuthorizationCodeForAccessToken } from '../../api/calendlyAuth';
function Callback() {
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  useEffect(() => {
    const exchangeCodeForAccessToken = async (code) => {
      try {
        const accessToken = await exchangeAuthorizationCodeForAccessToken(code);

        // Save the access token to localStorage, or handle it as required by your app
        localStorage.setItem('calendlyAccessToken', accessToken);

        // Redirect the user to the desired page after successful integration
        navigate('/profile'); // Change this to the desired route
      } catch (error) {
        console.error('Error exchanging authorization code for access token:', error);
      }
    };

    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');

    if (code) {
      exchangeCodeForAccessToken(code);
    } else {
      // Handle the case where there is no "code" parameter in the callback URL
      console.error('No authorization code found in the URL');
    }
  }, [navigate]);

  return (
    <div>
      <p>Connecting with Calendly...</p>
    </div>
  );
}

export default Callback;
