import React from 'react';
import { useSelector } from 'react-redux';
// import Post from '../components/Post/Post';
import SearchPost from '../components/Post/SearchPost';
import User from '../components/User/User';
import { getPostsBySearch } from '../actions/posts';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './search.css';
import { Link } from 'react-router-dom';

const Search = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const searchResults = useSelector((state) => state.search);
  // console.log("searchResults", searchResults)
  const { posts, users } = searchResults;

  const getSearchQuery = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('searchQuery');
  };

  useEffect(() => {
    const searchQuery = getSearchQuery();
    if (searchQuery) {
      dispatch(getPostsBySearch({ search: searchQuery }));
    }
  }, [dispatch]);

  return (
    <div className="search-results-container">
      <div className="search-results-header">
        <h1>Search Results</h1>
      </div>

      <div className="search-results-section">
        <h2 className="custom-h2">Posts</h2>
        {posts && posts.length > 0 ? (
          <ul className="search-results-list">
            {posts.map((post) => (
              <li key={post.id}>
                <SearchPost post={post} />
              </li>
            ))}
          </ul>
        ) : (
          <p className="custom-p">No posts found.</p>
        )}
      </div>

      <div className="search-results-section">
        <h2 className="custom-h2">Users</h2>
        {users && users.length > 0 ? (
          <div className="search-results-list">

            {users.map((user) => (
              <>
                <Link className="custom-link" to={`/user/${user?.username}`}>
                  Go to {user?.username}
                </Link>
                <li key={user.id}>
                  <User user={user} />
                </li>
              </>
            ))}
          </div>
        ) : (
          <p className="custom-p">No users found.</p>
        )}
      </div>
    </div>
  )
};

export default Search;
