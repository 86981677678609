import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import TimezonePicker from "react-timezone-select";
import logo2 from "../../images/logo2.jpg";
import { useDispatch } from "react-redux";
import { createEvent } from "../../actions/events";
import { createUserStripeAccount, getUser, verifyStripeAccount } from "../../api";
import './create.css';

function Create({ myEvents, setMyEvents }) {
  const [formData, setFormData] = useState({});
  const [activeItemId, setActiveItemId] = useState("virtualEvent");
  const [isPaid, setIsPaid] = useState(false);
  const [image, setImage] = useState(null);
  const [timeZone, setTimeZone] = useState("");
  const user = localStorage.getItem("username");

  const [userData, setUserData] = useState(null)


  const dispatch = useDispatch();

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleTimeZoneChange = (selectedTimeZone) => {
    setTimeZone(selectedTimeZone);

    setFormData({ ...formData, eventTimeZone: selectedTimeZone });
  };
  useEffect(() => {
    formData.eventImg = image;

    const getUserData = async () => {
      const _userData = await getUser(user);
      console.log(_userData.data)
      setUserData(_userData.data);
    }

    getUserData();
    // console.log(formData.eventImg);
  }, [image]);

  const handleFileChange = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setFormData({ ...formData, [event.target.name]: image });
  };

  const handleClick = (e) => {
    if (e.target.id === "pricePaid") {
      setIsPaid(true);
    } else if (e.target.id === "priceFree") {
      setIsPaid(false);
    } else {
      setActiveItemId(e.target.id);
    }
  };
  const handleClick2 = () => {
    document.getElementById("eventImg").click();
  };

  const formDisplay = () => {
    if (activeItemId === "virtualEvent") {
      return (
        <Row>
          <Col>
            <div className="formbarEvent fade-in">
              <input
                placeholder="Tags"
                type="text"
                value={formData.tags}
                name="tags"
                onChange={handleChange}
                className="formInputEvent"
              />
            </div>
          </Col>
          <Col>
            <div className="formbarEvent fade-in">
              <input
                placeholder="Total no. of attendees"
                type="number"
                value={formData.noAttendees}
                name="noAttendees"
                onChange={handleChange}
                className="formInputEvent"
                min="1"
              />
            </div>
          </Col>
        </Row>
      );
    } else if (activeItemId === "onSiteEvent") {
      return (
        <>
          <Row>
            <Col>
              <div className="formbarEvent fade-in">
                <input
                  placeholder="Tags"
                  type="text"
                  value={formData.tags}
                  name="tags"
                  onChange={handleChange}
                  className="formInputEvent"
                />
              </div>
            </Col>
            <Col>
              <div className="formbarEvent fade-in">
                <input
                  placeholder="Total no. of tickets"
                  type="number"
                  value={formData.noTickets}
                  name="noTickets"
                  onChange={handleChange}
                  className="formInputEvent"
                  min="1"
                />
              </div>
            </Col>
          </Row>
          <br />
          <Row>
          <Col>
              <div className="formbarEvent fade-in">
                <input
                  placeholder="Event Location"
                  type="text"
                  value={formData.eventLoc}
                  name="eventLoc"
                  onChange={handleChange}
                  className="formInputEvent"
                />
              </div>
            </Col>
          </Row>
        </>
      );
    } else if (activeItemId === "hybridEvent") {
      return (
        <>
          <Row>
            <Col>
              <div className="formbarEvent fade-in">
                <input
                  placeholder="Tags"
                  type="text"
                  value={formData.tags}
                  name="tags"
                  onChange={handleChange}
                  className="formInputEvent"
                />
              </div>
            </Col>
            <Col>
              <div className="formbarEvent fade-in">
                <input
                  placeholder="Total no. of attendees"
                  type="number"
                  value={formData.noAttendees}
                  name="noAttendees"
                  onChange={handleChange}
                  className="formInputEvent"
                  min="1"
                />
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <div className="formbarEvent fade-in">
                <input
                  placeholder="Event Location"
                  type="text"
                  value={formData.eventLoc}
                  name="eventLoc"
                  onChange={handleChange}
                  className="formInputEvent"
                />
              </div>
            </Col>
            <Col>
              <div className="formbarEvent fade-in">
                <input
                  placeholder="Total no. of tickets"
                  type="number"
                  value={formData.noTickets}
                  name="noTickets"
                  onChange={handleChange}
                  className="formInputEvent"
                  min="1"
                />
              </div>
            </Col>
          </Row>
        </>
      );
    }
  };

  async function handleConnectToStripe() {
    try {
      const response = await createUserStripeAccount(user)
      // console.log(response);
      // Save the Stripe account ID in local storage
      // localStorage.setItem('stripeAccountId', response.data.stripeAccountId);

      // // Redirect the user to the Stripe onboarding URL
      // window.location.href = response.data.url;
    } catch (error) {
      console.error('Error connecting to Stripe:', error);
    }
  }

  async function handleVerifyStripeAccount() {
    await verifyStripeAccount(user)
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    let id = formData.length + 1;
    setFormData({ ...formData, id: id });

    if (
      !formData.eventName ||
      !formData.eventMode ||
      !formData.eventPrice ||
      !formData.accessLevel ||
      !formData.eventDateTime ||
      !formData.endTime ||
      !formData.EventDescription ||
      !timeZone
    ) {
      // console.log(formData);
      alert("Please fill out all required fields");
      return;
    }
    if (activeItemId === "virtualEvent") {
      if (!formData.noAttendees || !formData.tags) {
        alert("Please fill out all required fields");
        return;
      }
    } else if (activeItemId === "onSiteEvent") {
      if (!formData.noTickets || !formData.eventLoc) {
        alert("Please fill out all required fields");
        return;
      }
    } else if (activeItemId === "hybridEvent") {
      if (
        !formData.noTickets ||
        !formData.tags ||
        !formData.noAttendees ||
        !formData.eventLoc
      ) {
        alert("Please fill out all required fields");
        return;
      }
    }

    if (isPaid) {
      if (!formData.eventPriceInput) {
        alert("Please fill out all required fields");
        return;
      }
      if (!userData.stripeVerified == true) {
        alert("Please verify your stripe account");
        return;
      }
    }

    // console.log(formData);
    dispatch(createEvent(formData, user));

    setImage(null);
    setTimeZone("");
    setIsPaid(false);
    setActiveItemId("virtualEvent");
    setFormData({
      eventImg: "",
      eventName: "",
      eventMode: "",
      eventPrice: "",
      eventPriceInput: "",
      accessLevel: "",
      eventDateTime: "",
      endTime: "",
      EventDescription: "",
      noTickets: "",
      tags: "",
      noAttendees: "",
      eventLoc: "",
      durationMeasure: "",
    });

    window.location.reload()
  };
  return (
    <div>
      <br />
      <h4>Create New Event</h4>
      <br />
      <form onSubmit={handleSubmit}>
        <Row>
          <Col style={{ textAlign: "left" }}>
            {/* <div style={{ position: "relative" }}>
              <img className="uploadImg" src={image || logo2} alt="profile" />
              <div className="uploadImg2" onClick={handleClick2}>
                <input
                  style={{ display: "none" }}
                  id="eventImg"
                  name="eventImg"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />

                <p
                  style={{
                    position: "relative",
                    textAlign: "center",
                    margin: "auto",
                    color: "black",
                    top: "45%",
                  }}
                >
                  Upload Photo
                </p>
              </div>
            </div> */}
            {!userData || !userData.stripeAccountId ? (
              <button
                type="button"
                className="connect-to-stripe"
                onClick={handleConnectToStripe}
              >
                Connect To Stripe
              </button>
            ) : null}
            <br />
            {!userData || !userData.stripeAccountId || !(userData.stripeVerified === true) ? (
              <button
                type="button"
                className="connect-to-stripe"
                onClick={handleVerifyStripeAccount}
              >
                Verify Stripe Account
              </button>
            ) : null}
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <div className="formbarEvent">
              <input
                placeholder="Enter Event Name"
                type="text"
                name="eventName"
                value={formData.eventName}
                onChange={handleChange}
                className="formInputEvent"
              />
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Row>
              <div style={{ textAlign: "left" }}>
                <p style={{ fontWeight: "bold" }}>Event Mode</p>
                <Row>
                  <Col lg={6}>
                    <input
                      type="radio"
                      id="virtualEvent"
                      name="eventMode"
                      value="Virtual Event"
                      checked={formData.eventMode === "Virtual Event"}
                      onChange={handleChange}
                      onClick={handleClick}
                    />
                    <label className="eventLabel" htmlFor="virtualEvent">
                      Virtual Event
                    </label>
                  </Col>
                  <Col lg={6}>
                    <input
                      type="radio"
                      id="onSiteEvent"
                      name="eventMode"
                      value="On-Site Event"
                      checked={formData.eventMode === "On-Site Event"}
                      onChange={handleChange}
                      onClick={handleClick}
                    />
                    <label className="eventLabel" htmlFor="onSiteEvent">
                      On-Site Event
                    </label>
                  </Col>
                  <Col lg={12}>
                    <input
                      type="radio"
                      id="hybridEvent"
                      name="eventMode"
                      value="Hybrid Event"
                      checked={formData.eventMode === "Hybrid Event"}
                      onChange={handleChange}
                      onClick={handleClick}
                    />
                    <label className="eventLabel" htmlFor="hybridEvent">
                      Hybrid Event
                    </label>
                  </Col>
                </Row>
              </div>
            </Row>
          </Col>
          <Col>
            <Row>
              <div style={{ textAlign: "left" }}>
                <p style={{ fontWeight: "bold" }}>Event Price</p>
                <Row>
                  <Col>
                    <input
                      type="radio"
                      id="priceFree"
                      name="eventPrice"
                      value="Free"
                      checked={formData.eventPrice === "Free"}
                      onChange={handleChange}
                      onClick={handleClick}
                    />
                    <label className="eventLabel" htmlFor="virtualEvent">
                      Free
                    </label>
                  </Col>
                  <Col>
                    <input
                      type="radio"
                      id="pricePaid"
                      name="eventPrice"
                      value="Paid"
                      checked={formData.eventPrice === "Paid"}
                      onChange={handleChange}
                      onClick={handleClick}
                    />

                    <label className="eventLabel" htmlFor="onSiteEvent">
                      Paid
                    </label>
                  </Col>
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Row>
              <Col xs={6}>
                <div className="formbarEvent">
                  <label>Start Date & Time</label>
                  <input
                    type="datetime-local"
                    value={formData.eventDateTime}
                    id="eventDateTime"
                    name="eventDateTime"
                    className="formInputEvent"
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col xs={6}>
                <div className="formbarEvent">
                  <TimezonePicker
                    id="timeZone"
                    name="eventTimeZone"
                    className="formInputEvent timezone"
                    placeholder="Time Zone"
                    value={{ label: timeZone.label }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: "transparent",
                        border: "none",
                        textAlign: "left",
                        boxShadow: state.isFocused
                          ? "none"
                          : baseStyles.boxShadow,
                        padding: "5px",
                        color: "white",
                      }),
                      option: (baseStyles) => ({
                        ...baseStyles,

                        boxShadow: "none",
                        color: "black",
                      }),
                      input: (baseStyles) => ({
                        ...baseStyles,

                        color: "white",
                      }),
                      singleValue: (baseStyles) => ({
                        ...baseStyles,

                        color: "white",
                      }),
                    }}
                    onChange={handleTimeZoneChange}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col>
            <div className="formbarEvent">
              <select
                id="accessLevel"
                name="accessLevel"
                value={formData.accessLevel}
                onChange={handleChange}
                className="formInputEvent"
                style={{ width: "96%", color: "black" }}
              >
                <option value="">Select Access Level</option>
                <option value="public">Public</option>
                <option value="private">Private</option>
              </select>
            </div>
          </Col>
        </Row>
        <br />
        {formDisplay()}
        <br />
        <Row>
          <Col>
            <div className="formbarEvent">
              <label>End Date & Time</label>
              <input
                type="datetime-local"
                value={formData.endTime}
                id="endTime"
                name="endTime"
                className="formInputEvent"
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col>
            {isPaid ? (
              <div className="formbarEvent fade-in">
                <input
                  placeholder="Enter Price ($)"
                  type="text"
                  value={formData.eventPriceInput}
                  name="eventPriceInput"
                  onChange={handleChange}
                  onKeyDown={(event) => {
                    if (!/\d/.test(event.key) && event.key !== "Backspace") {
                      event.preventDefault();
                    }
                  }}
                  className="formInputEvent"
                />
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <textarea
              placeholder="Any Links & More Event Information Go Here ..."
              name="EventDescription"
              value={formData.EventDescription}
              onChange={handleChange}
              className="formTextAreaEvent"
            />
          </Col>
        </Row>
        <br />
        <button type="submit" className="form-submit">
          Create
        </button>
      </form>
    </div>
  );
}

export default Create;
