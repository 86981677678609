import { React, useState, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import About from "./pages/About";
import Navigation from "./components/Navbar/Navigation";
import ProfileNavigation from "./components/Navbar/ProfileNavigation";
import Footer from "./components/Footer";
import Modal from "./components/Booking/Modal";
import "./App.css";
import Register from "./pages/Register.js";
import Login from "./pages/Login";
import Profile2 from "./pages/Profile2";
import Success from "./components/Stripe/Success";
import Verification from "./pages/Verification";
import EventPage from "./pages/Event";
import UserPage from "./pages/User";
import StripeContainer from "./components/Stripe/StripeContainer";
import { exchangeAuthorizationCodeForAccessToken } from "./api/calendlyAuth";
import Callback from "./components/Calendly/Callback";
import { useDispatch } from "react-redux";
import { getPosts } from "./actions/posts";
import { fetchEvents } from "./actions/events";
import Search from "./pages/Search";

function App() {
  const [modalOpen, setOpenModal] = useState(false);
  const [isAppointment, setAppointment] = useState(false);

  async function handleCalendlyCallback(code) {
    const accessToken = await exchangeAuthorizationCodeForAccessToken(code);
    console.log("Access Token:", accessToken);
    // Store the access token and use it to create events, fetch user data, etc.
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPosts());
    dispatch(fetchEvents());
  }, []);

  return (
    <>
      {modalOpen && (
        <Modal
          isAppointment={isAppointment}
          modalOpen={modalOpen}
          setOpenModal={setOpenModal}
        />
      )}

      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navigation solidBg={false} />

              <div className="bg">
                <hr
                  style={{
                    padding: "0px",
                    margin: "0px",
                    border: "0px",
                    backgroundColor: "transparent",
                  }}
                  id="home"
                />
                <section
                  style={{
                    width: "100%",
                    margin: "0 auto",
                    position: "absolute",
                    top: "35%",
                  }}
                >
                  <div style={{ opacity: "0.9" }}>
                    <h1 style={{ color: "white", fontWeight: "bold",lineHeight: '60px' }}>
                      5D HEALERVERSE
                    </h1>
                    <h1 className="title">
                      The Evolution of Healing - Your Community
                    </h1>
                  </div>
                  <br />
                  <p className="home-text">
                    Enlightened Social Community and Space for Healing, Healers
                    and the New Earth.
                    <br />
                    Welcome Spiritual and Holistic Healers and Organizations!
                    <br />
                    Welcome to those seeking Healers, Workshops & Events in our
                    Local and Global Communities!
                    <br />
                  </p>

                  {/* <LinkContainer to="/register">
										<Button className="mt-5 home-btn">REGISTER NOW</Button>
									</LinkContainer> */}
                </section>

                {/* <div className="faded-out"></div> */}
              </div>

              {/* <section
								style={{
									width: "85vw",
									margin: "auto",
									position: "relative",
									top: "110px",
								}}
							></section> */}
              <Container
                style={{
                  position: "relative",
                  top: "110px",
                  width: "100%",
                }}
                className="bg2"
                fluid
              >
                <div className="faded-out"></div>
                <section
                  style={{
                    width: "85vw",
                    margin: "auto",
                    position: "relative",
                    top: "80px",
                  }}
                >
                  <div style={{ opacity: "0.9" }}>
                    {/* <h4 style={{ color: "white" }}>
											Healerverse gives humanity the power to navigate and
											protect their own spiritual and holistic healing journey.
										</h4>
										<h5 style={{ color: "white", fontWeight: "bold" }}>
											For those seeking transformative connections in our local
											and global communities.
											<br />
											<br />
											Our Mission: To level up global collective consciousness
											by dissolving barriers with unity through healing and
											transformation of the mind, body and spirit.
											<br />
											<br />
											Our Vision: Heal yourself, Heal others, Heal the world...
											Healerverse.
											<br />
											<br />
											Metaverse: We are launching our NFT community soon, please
											visit here for updates:
											<a
												href="http://NFTHealerverse.io"
												target="_blank"
												rel="noopener noreferrer"
												style={{ color: '#aa00ff', fontWeight: 'bold' }}
											>
												{" "}
												<br />
												NFTHealerverse.io
											</a>
										</h5> */}
                    <div
                      style={{ position: "relative", bottom: "-30vh" }}
                      class="sub-content"
                    >
                      {/* <LinkContainer to="/register">
                        <Button className="mt-3 home-btn mb-3">
                          REGISTER NOW
                        </Button>
                      </LinkContainer> */}

                      <h1 style={{ color: "white" }}>Find Your Tribe.</h1>
                    </div>
                  </div>
                </section>

                <div className="faded-out"></div>
              </Container>
              <br />
              <br />
              <br />
              <br />
              <Footer />
            </>
          }
        ></Route>
        <Route
          path="/register"
          element={
            <>
              <Navigation solidBg={true} />
              <Register />
            </>
          }
        ></Route>
        <Route
          path="/about"
          element={
            <>
              <Navigation solidBg={true} />
              <About />
              <br />
              <br />
              <br />
              <br />
              <Footer />
            </>
          }
        ></Route>
        <Route
          path="/login"
          element={
            <>
              <Navigation solidBg={true} />

              <Login />
            </>
          }
        ></Route>
        <Route
          path="/checkout"
          element={
            <>
              <Navigation solidBg={true} />

              <StripeContainer />
            </>
          }
        ></Route>
        <Route
          path="/success"
          element={
            <>
              <Navigation solidBg={true} />

              <Success />
            </>
          }
        ></Route>
        <Route
          path="/verification"
          element={
            <>
              <Navigation solidBg={true} />

              <Verification />
            </>
          }
        ></Route>
        <Route
          path="/profile"
          element={
            <>
              <ProfileNavigation />
              <br />
              <br />
              <br />
              <br />
              <br />
              <Profile2 />
            </>
          }
        ></Route>
        <Route
          path="/events/:id"
          element={
            <>
              <Navigation solidBg={true} />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <EventPage />
            </>
          }
        ></Route>
        <Route path="/calendly/callback" element={<Callback />} />
        <Route
          path="/search"
          element={
            <>
              <Navigation solidBg={true} />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <Search />
            </>
          }
        ></Route>
        <Route
          path="/user/:username"
          element={
            <>
              <ProfileNavigation />
              <br />
              <br />
              <br />
              <br />
              <br />
              <UserPage />
            </>
          }
        ></Route>
      </Routes>
    </>
  );
}

export default App;
