import { FETCH_ALL, CREATE, UPDATE, DELETE, FETCH_BY_SEARCH, COMMENT, SET_SEARCH_RESULTS } from '../constants/actionTypes';
import * as api from '../api';

/** ACTIONS */

/////////////////////////////** Posts *//////////////////////////////

export const getPosts = () => async (dispatch) => {
    try {
        const { data } = await api.fetchPosts();

        dispatch({ type: FETCH_ALL, payload: data });
    } catch (error) {
        console.log('Error: ', error.message);
    }
}

export const getPostsBySearch = (searchQuery) => async (dispatch) => {
    try {
        const { data } = await api.fetchPostsBySearch(searchQuery);
        // console.log(data)
        if (data.posts.length > 0 || data.users.length > 0) {
            // dispatch({ type: FETCH_BY_SEARCH, payload: data.posts });
            dispatch({ type: SET_SEARCH_RESULTS, payload: data }) // @amaechi once search page is ready we can use this, for now we are searching for posts only
        } else {
            getPosts()(dispatch)
        }
        return data
    } catch (error) {
        console.log(error)
    }
}

export const createPost = (post) => async (dispatch) => {
    try {
        const { data } = await api.createPost(post);

        dispatch({ type: CREATE, payload: data })
    } catch (error) {
        console.log('Error: ', error.message)
    }
}

export const deletePost = (id) => async (dispatch) => {
    try {
        await api.deletePost(id);

        dispatch({ type: DELETE, payload: id })
    } catch (error) {
        console.log('Error: ', error.message)
    }
}

export const likePost = (id) => async (dispatch) => {
    try {
        const { data } = await api.likePost(id);

        dispatch({ type: UPDATE, payload: data })
    } catch (error) {
        console.log('Error: ', error.message)
    }
}

export const newComment = (value, id) => async (dispatch) => {
    try {
        const { data } = await api.newComment(value, id);
        console.log(data)
        dispatch({ type: COMMENT, payload: data })

        return data.comments;
    } catch (error) {
        console.log('Error: ', error.message)
    }
}

