import React from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

function AppointmentDetails({ formData, setFormData }) {
	const handleHealerChange = (event: SelectChangeEvent) => {
		setFormData({ ...formData, healer: event.target.value });
	};
	const handleLocationChange = (event: SelectChangeEvent) => {
		setFormData({ ...formData, location: event.target.value });
	};
	return (
		<>
			<FormControl fullWidth style={{ textAlign: "left" }}>
				<InputLabel>Select Healer</InputLabel>
				<Select
					value={formData.healer}
					label="Select Healer"
					onChange={handleHealerChange}
				>
					<MenuItem value="healer1">Healer 1</MenuItem>
					<MenuItem value="healer2">Healer 2</MenuItem>
					<MenuItem value="healer3">Healer 3</MenuItem>
				</Select>
			</FormControl>
			<br />
			<br />
			<FormControl fullWidth style={{ textAlign: "left" }}>
				<InputLabel>Select Where</InputLabel>
				<Select
					value={formData.location}
					label="Select Where"
					onChange={handleLocationChange}
				>
					<MenuItem value="online">Online</MenuItem>
					<MenuItem value="inPerson">In Person</MenuItem>
				</Select>
			</FormControl>
		</>
	);
}

export default AppointmentDetails;
