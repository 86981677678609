import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";
import * as SiIcons from "react-icons/si";

function Footer() {
  return (
    <div
    //   style={{
    //     width: "85vw",
    //     margin: "auto",
    //     padding: "70px 0px 40px 0px",
    //     textAlign: "left",
    //   }}
    // style={{ margin: "auto", width: "60vw" }}
    >
      <Container className="mt-5">
        <div>
          <Row>
            <Col lg={4}>
              <h5 style={{ color: "white" }}>5D HEALERVERSE</h5>
              <p className="text-muted">
                A social platform that connects people. A space devoted to
                wellness on all levels, including body, mind and spirit.
              </p>
            </Col>
            <Col lg={4}>
              <h5 style={{ color: "white" }}>CONTACT US</h5>
              <Row>
                <Col xs={1}>
                  <AiIcons.AiOutlineMail className="text-muted" />
                </Col>
                <Col>
                  <p className="text-muted">
                    PRESS ENQUIRIES:{" "}
                    <a href="mailto:media@5dhealerverse.com">
                      media@5dhealerverse.com
                    </a>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={1}>
                  <AiIcons.AiOutlineMail className="text-muted" />
                </Col>
                <Col>
                  <p className="text-muted">
                    SUPPORT:{" "}
                    <a href="mailto:support@5dhealerverse.com">
                      support@5dhealerverse.com
                    </a>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={1}>
                  <AiIcons.AiOutlineMail className="text-muted" />
                </Col>
                <Col>
                  <p className="text-muted">
                    If you are interesting in being an angel investor and
                    strategic partner:{" "}
                    <a href="mailto:finance@5dhealerverse.com">
                      finance@5dhealerverse.com
                    </a>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col lg={4}>
              <h5 style={{ color: "white" }}>FOLLOW US</h5>
              <div style={{ display: "flex" }}>
                <Row>
                  <Col xs={4}>
                    <a
                      href="https://www.linkedin.com/company/healerverse5d/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AiIcons.AiOutlineLinkedin className="text-muted" />
                    </a>
                  </Col>
                  <Col xs={4}>
                    <a
                      href="https://twitter.com/healerverse"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <FiIcons.FiTwitter className="text-muted" />
                    </a>
                  </Col>
                </Row>
              </div>
              <div style={{ display: "flex" }}>
                <Row>
                  <Col xs={4}>
                    <a
                      href="https://discord.com/invite/NAtgSJWW7z"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SiIcons.SiDiscord className="text-muted" />
                    </a>
                  </Col>
                  <Col xs={4}>
                    <a
                      href="https://t.me/+Ixv07pe37xA1MDNh"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <SiIcons.SiTelegram className="text-muted" />
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <br />
        <br />

        <Row>
          <Col>
            <a
              href={require("../pdfs/Healerverse - Full Site Terms of Service FINAL.pdf")}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#aa00ff" }}
            >
              <p>Terms & Conditions</p>
            </a>
          </Col>
          <Col>
            <a
              href={require("../pdfs/Healerverse - Full Site Privacy Policy FINAL.pdf")}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#aa00ff" }}
            >
              <p>Privacy Policy</p>
            </a>
          </Col>
          <Col>
            <a
              href={require("../pdfs/Healerverse - Community Standards FINAL.pdf")}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#aa00ff" }}
            >
              <p>Community Standards</p>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
