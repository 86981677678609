import React from 'react';
import moment from "moment";
  
const SearchPost = ({ post }) => {
  return (
    <div className="searchPost">
      <div className="searchPostWrapper">
        <div className="searchPostTop">
          <div className="searchPostTopLeft">
            <span className="searchPostUsername">{post.username}</span>
            <br />
            <span className="searchPostDate">
              {post.date ? moment(post.date)?.fromNow() : 'a long time ago'}
            </span>
          </div>
        </div>
        <div className="searchPostCenter">
          <span className="searchPostText">{post?.desc}</span>
          <img className="searchPostImg" src={post.photo} alt="" />
        </div>
        {post.tags.length > 0 &&
          post.tags[0] !== '' &&
          post.tags[0] !== ' ' && (
            <span className="searchPostDate">
              {post.tags.map((tag) => (
                <span key={tag}> #{tag} </span>
              ))}
            </span>
          )}
      </div>
    </div>
  );
};

export default SearchPost;
