import React from "react";
import { Container, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

function Success() {
	return (
		<Container
			fluid
			style={{
				margin: "auto",
				textAlign: "center",
				width: "85vw",
				position: "relative",
				top: "100px",
			}}
		>
			<h2 style={{ color: "white", paddingBottom: "10px" }}>
				Payment Successful
			</h2>
			<br />
			<p style={{ color: "white", paddingBottom: "10px" }}>
				Your payment was successful! You will receive an
				email shortly.
			</p>
			<br />
			<LinkContainer style={{ width: "15em" }} to="/">
				<Button className="home-btn" id="btn-purple">
					Return to Home Page
				</Button>
			</LinkContainer>
		</Container>
	);
}

export default Success;
