import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getUser, fetchEventByUser } from '../api';
import './user.css';

const UserPage = () => {

    const [userData, setUserData] = useState(null);
    const { username } = useParams();
    const [myEvents, setMyEvents] = useState([]);

    // console.log("username ", username);
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await getUser(username);
                console.log("response ", response);
                setUserData(response);
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };

        const fetchEvents = async () => {
            try {
              const response = await fetchEventByUser(username);
              console.log(response);
              if (response && response.data) {
                setMyEvents(response.data);
              }
            } catch (error) {
              console.error('Error fetching events:', error);
            }
          };

        fetchEvents();
        fetchUserData();
    }, [username]);

    if (!userData) {
        return <div>Loading...</div>;
    }

    const {
        firstName,
        lastName,
        email,
        healerStatus,
        createdEvents,
    } = userData;

    console.log("userData ", userData);
    return (
        <div className="user-container">
            <div className="user-header">
                <img src={userData.profileImage} className="logo" />
                <h2>{userData.firstName} {userData.lastName}</h2>
            </div>
            <div className="user-body">
                <div className="user-info">
                    <p><strong>Email:</strong> {userData.email}</p>
                    <p><strong>Status:</strong> {userData.healerStatus ? "Verified Healer" : "Seeker"}</p>
                    <p><strong>Bio:</strong> {userData.bio}</p>
                    <p><strong>Date of Birth:</strong> {userData.dateOfBirth}</p>
                    <p><strong>Gender:</strong> {userData.gender}</p>
                    <p><strong>Country:</strong> {userData.country}</p>
                    <p><strong>City:</strong> {userData.city}</p>
                    <p><strong>Tags:</strong>{" "}
                        {userData.tags && userData.tags.map((tag, index) => (
                            <span key={index} className="user-tag">{tag}</span>
                        ))}
                    </p>
                </div>
                <div className="user-created-events">
                    <h3>Created Events</h3>
                    {userData.createdEvents && userData.createdEvents.length === 0 ? (
                        <p>No events created.</p>
                    ) : (
                        <ul>
                            {userData.createdEvents && myEvents.map((event, index) => (
                                <li key={index}>
                                    <a href={`https://www.5dhealerverse.com/events/${event._id}`}>{event.eventName}</a>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserPage;

