import { combineReducers } from 'redux';

import posts from './posts';
import events from './events';
import search from './search';

export default combineReducers({ 
    posts,
    events,
    search
})