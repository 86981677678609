import { SET_SEARCH_RESULTS } from '../constants/actionTypes';

const initialState = {
    posts: [],
    users: [],
  };

export default (search=initialState, action) => {
    switch (action.type) {
        case SET_SEARCH_RESULTS:
            // console.log('action.payload', action.payload)
            return action.payload;
        default:
            return search;
    }
};
