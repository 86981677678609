import React, { useState } from "react";
import { Col, Container, Row, Button, Modal } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import { registerUser } from "../api/index";
import loader from "../images/EllipsisLoader.gif";

function Register() {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [registerStatus, setRegisterStatus] = useState("");
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const [registerData, setRegisterData] = useState({
    username: "",
    password: "",
    email: "",
    firstName: "",
    lastName: "",
    healerStatus: false,
    dateOfBirth: "",
    infoSold: false,
  });

  const [errors, setErrors] = useState({
    username: false,
    password: false,
    email: false,
    firstName: false,
    lastName: false,
    dateOfBirth: false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check each field and update the error state accordingly
    const newErrors = {};
    if (registerData.username === "") {
      newErrors.username = true;
    }
    if (registerData.firstName === "") {
      newErrors.firstName = true;
    }
    if (registerData.lastName === "") {
      newErrors.lastName = true;
    }
    if (registerData.email === "") {
      newErrors.email = true;
    }
    if (registerData.dateOfBirth === "") {
      newErrors.dateOfBirth = true;
    }

    if (registerData.password === "") {
      newErrors.password = true;
    }

    setErrors(newErrors);

    // Check if there are any errors
    if (Object.values(newErrors).some((error) => error)) {
      return;
    }

    setIsLoading(true);

    registerUser(registerData).then((res) => {
      setIsLoading(false);
      console.log("res: ", res);
      if (res?.status === 200 || res?.status === 201) {
        setRegisterStatus("Successfuly Registered!");
        window.location.replace("/verification");
      } else {
        setIsModalOpen(true);
        setTimeout(() => setIsModalOpen(false), 5000);
        setRegisterStatus("Could not register.");
      }
    });
  };

  return (
    <Container
      fluid
      style={{
        margin: "auto",
        textAlign: "center",
        width: "85vw",
        position: "relative",
        top: "100px",
      }}
    >
      <h2 style={{ color: "white", paddingBottom: "30px" }}>
        Create a new account
      </h2>

      <div
        style={{ border: errors.username ? "1px solid #fd7862" : "" }}
        className="formbarMain"
      >
        <input
          placeholder="Username"
          type="text"
          name="username"
          value={registerData.username}
          className="formInputMain"
          onChange={(e) => {
            setRegisterData({ ...registerData, username: e.target.value });
            setErrors((prevErrors) => ({ ...prevErrors, username: false }));
          }}
        />
      </div>

      <br />
      <div
        style={{ border: errors.firstName ? "1px solid #fd7862" : "" }}
        className="formbarMain"
      >
        <input
          placeholder="John"
          type="text"
          name="fName"
          value={registerData.firstName}
          className="formInputMain"
          onChange={(e) => {
            setRegisterData({ ...registerData, firstName: e.target.value });
            setErrors((prevErrors) => ({ ...prevErrors, firstName: false }));
          }}
        />
      </div>

      <br />
      <div
        style={{ border: errors.lastName ? "1px solid #fd7862" : "" }}
        className="formbarMain"
      >
        <input
          placeholder="Smith"
          type="text"
          name="lName"
          value={registerData.lastName}
          className="formInputMain"
          onChange={(e) => {
            setRegisterData({ ...registerData, lastName: e.target.value });
            setErrors((prevErrors) => ({ ...prevErrors, lastName: false }));
          }}
        />
      </div>

      <br />
      <div
        style={{ border: errors.email ? "1px solid #fd7862" : "" }}
        className="formbarMain"
      >
        <input
          placeholder="example@example.com"
          type="text"
          name="email"
          value={registerData.email}
          className="formInputMain"
          onChange={(e) => {
            setRegisterData({ ...registerData, email: e.target.value });
            setErrors((prevErrors) => ({ ...prevErrors, email: false }));
          }}
        />
      </div>

      <br />
      <div
        style={{ border: errors.password ? "1px solid #fd7862" : "" }}
        className="formbarMain"
      >
        <input
          placeholder="Password"
          type="password"
          name="password"
          value={registerData.password}
          className="formInputMain"
          onChange={(e) => {
            setRegisterData({ ...registerData, password: e.target.value });
            setErrors((prevErrors) => ({ ...prevErrors, password: false }));
          }}
        />
      </div>

      <br />
      <div
        style={{ border: errors.dateOfBirth ? "1px solid #fd7862" : "" }}
        className="formbarMain"
      >
        <input
          placeholder="DD/MM/YYYY"
          type="text"
          name="dob"
          value={registerData.dateOfBirth}
          className="formInputMain"
          onChange={(e) => {
            setRegisterData({ ...registerData, dateOfBirth: e.target.value });
            setErrors((prevErrors) => ({ ...prevErrors, dateOfBirth: false }));
          }}
        />
      </div>

      <br />
      <div
        style={{
          textAlign: "center",
          border: "none",
          color: "white",

          margin: "auto",
          marginBottom: "50px",
        }}
        className="resWidth"
      >
        <br />
        <Row style={{ margin: "auto" }} className="resWidth">
          <Col xs={2} style={{ margin: "auto", textAlign: "center" }}>
            <Checkbox style={{ color: "white" }} />
          </Col>
          <Col style={{ margin: "auto", textAlign: "center" }}>
            <p
              style={{
                fontSize: "10px",
                margin: "auto",
                textAlign: "center",
              }}
            >
              By continuing, you agree with our{" "}
              <a
                href={require("../pdfs/Healerverse - Full Site Terms of Service FINAL.pdf")}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#aa00ff" }}
              >
                Terms of Service
              </a>
              ,{" "}
              <a
                href={require("../pdfs/Healerverse - Full Site Privacy Policy FINAL.pdf")}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#aa00ff" }}
              >
                Privacy Policy
              </a>{" "}
              &{" "}
              <a
                href={require("../pdfs/Healerverse - Community Standards FINAL.pdf")}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#aa00ff" }}
              >
                Community Standards
              </a>
              .
            </p>
          </Col>
        </Row>
      </div>

      <Button
        onClick={handleSubmit}
        className="home-btn"
        id="btn-purple"
        style={{ width: "10em" }}
      >
        {isLoading ? (
          <img style={{ width: "20px" }} src={loader} alt="Loading..." />
        ) : (
          "Sign Up"
        )}
      </Button>
      <Modal
        show={isModalOpen}
        onHide={toggleModal}
        animation={true}
        className="my-modal"
        centered
      >
        <Modal.Body
          style={{
            backgroundColor: "#454545",
            color: "white",
          }}
        >
          <h2>Regsitration Failed</h2>
          <p>Please check your details and try again.</p>
        </Modal.Body>
      </Modal>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
}

export default Register;
