import React from "react";
import "./eventCard.css";
import { Row, Col, Button } from "react-bootstrap";
import * as IoIcons from "react-icons/io5";
import clipboardCopy from 'clipboard-copy';
import { NavLink  } from 'react-router-dom';

function EventCard({ id, pic, title, date, link, price, location}) {
	const handleCopyClick = async () => {
		try {
		  await clipboardCopy(link);
		  alert('Link copied to clipboard!'); // @JACOB - make this a on screen popup like on the register/login page
		} catch (err) {
		  console.error('Failed to copy link: ', err);
		}
	  };
	
	// const openEvent = () => {
	// 	window.location.replace(`/events/${id}`);
	// }

	return (
		<>
		<div className="eventCard">
			{price === "Free" ? (
				<div className="freeTag">
					<p style={{ textAlign: "center", margin: "auto" }}>Free</p>
				</div>
			) : (
				<></>
			)}
			<NavLink to={`/events/${id}`} target="_blank" rel="noopener noreferrer">
				<img className="eventImg" src={pic} alt="" />
			</NavLink>
			<div className="eventBody">
				<h4 style={{ fontWeight: "bold" }}>{title}</h4>
				<Row>
					<Col xs={1}>
						<IoIcons.IoCalendar className="eventIcon" />
					</Col>
					<Col>{date}</Col>
				</Row>
				<Row>
					<Col xs={1}>
						<IoIcons.IoTicket className="eventIcon" />
					</Col>
					{price === 0 ? (
						<Col>Free</Col>
					) : (
						<Col>${price}</Col>
					)}
				</Row>
				<Row>
					<Col xs={1}>
						<IoIcons.IoLocation className="eventIcon" />
					</Col>
					<Col>{location}</Col>
				</Row>
				<Row>
					<Col xs={1}>
						<IoIcons.IoLink className="eventIcon" onClick={handleCopyClick} />
					</Col>
					<Col onClick={handleCopyClick} style={{color: '#af6ffb'}}>Copy Link</Col>
				</Row>
			</div>
		</div>
		</>
	);
}

export default EventCard;
