import React from "react";
import { Col, Row } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { TextFieldProps } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// import DatePicker from "react-datepicker";
// import TimePicker from "react-time-picker";

function AppointmentDetails({ formData, setFormData }) {
	// const [startDate, setStartDate] = useState(new Date());
	const [value, setValue] = React.useState<Date | null>(new Date());

	const handleDurationChange = (event: SelectChangeEvent) => {
		setFormData({ ...formData, duration: event.target.value });
	};
	return (
		<>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DesktopDatePicker
					label="Date"
					inputFormat="DD/MM/YYYY"
					value={value}
					onChange={(newValue) => {
						setValue(newValue);
						console.log(newValue);
						setFormData({ ...formData, date: value });
					}}
					renderInput={(params: TextFieldProps) => {
						return <TextField fullWidth {...params} />;
					}}
					views={["day"]}
					showDaysOutsideCurrentMonth
				/>
				<br />
				<br />
			</LocalizationProvider>

			<Row>
				<Col>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DesktopTimePicker
							label="Time"
							value={value}
							onChange={(newValue) => {
								setValue(newValue);
								console.log(newValue);
								setFormData({ ...formData, time: value });
							}}
							renderInput={(params: TextFieldProps) => (
								<TextField fullWidth {...params} />
							)}
						/>
					</LocalizationProvider>
				</Col>
				<Col>
					<FormControl fullWidth style={{ textAlign: "left" }}>
						<InputLabel>Duration</InputLabel>
						<Select
							value={formData.duration}
							label="Duration"
							onChange={handleDurationChange}
						>
							<MenuItem value={30}>30 mins</MenuItem>
							<MenuItem value={60}>60 mins</MenuItem>
							<MenuItem value={90}>90 mins</MenuItem>
							<MenuItem value={120}>120 mins</MenuItem>
						</Select>
					</FormControl>
				</Col>
			</Row>
		</>
	);
}

export default AppointmentDetails;
