import React from "react";
import { Col, Row } from "react-bootstrap";
import TextField from "@mui/material/TextField";

function PaymentDetails({ formData, setFormData }) {
	return (
		<>
			<Row>
				<Col>
					<TextField
						fullWidth
						required
						label="Name"
						name="nameCard"
						id="outlined-required"
						value={formData.name}
						placeholder="John Smith"
						InputLabelProps={{ shrink: true }}
						onChange={(e) => {
							setFormData({ ...formData, name: e.target.value });
						}}
					/>
				</Col>
				<Col>
					<TextField
						fullWidth
						required
						id="outlined-required"
						value={formData.cardNum}
						label="Card Number"
						InputLabelProps={{
							shrink: true,
						}}
						placeholder="1234 1234 1234 1234"
						onChange={(e) => {
							setFormData({ ...formData, cardNum: e.target.value });
						}}
					/>
				</Col>
			</Row>
			<br />
			<Row>
				<Col>
					<TextField
						fullWidth
						required
						label="Expiration Date"
						name="ccexp"
						id="outlined-required"
						value={formData.expiryDate}
						placeholder="MM/YY"
						InputLabelProps={{ shrink: true }}
						onChange={(e) => {
							setFormData({ ...formData, expiryDate: e.target.value });
						}}
					/>
				</Col>
				<Col>
					<TextField
						fullWidth
						required
						label="CVC"
						name="cvc"
						id="outlined-required"
						value={formData.cvc}
						placeholder="123"
						InputLabelProps={{ shrink: true }}
						onChange={(e) => {
							setFormData({ ...formData, cvc: e.target.value });
						}}
					/>
				</Col>
			</Row>
		</>
	);
}

export default PaymentDetails;
