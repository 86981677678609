import React, { useEffect, useState } from "react";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io5";
import decode from "jwt-decode";
import "./sidebar.css";

function Sidebar({ isOpen }) {
	let currentYear = new Date().getFullYear();
	const [activeItemId, setActiveItemId] = useState(null);

	useEffect(() => {

		const token = localStorage.getItem("token");
		if (!token) {
			window.location.replace("/login");
		} else {
			const decodedToken = decode(token)
			if (decodedToken.exp * 1000 < Date.now()) {
				localStorage.clear();
				window.location.replace("/login");
			}
		}

	}, []);

	const handleClick = (e) => {
		setActiveItemId(e.target.id);
		console.log(e.target.id);
	};

	const handleLogout = () => {
		localStorage.clear()
		window.location.replace("/");
	};

	return (
		<div className={`sidebar ${isOpen ? "open" : "closed"}`}>
			<div class="sidebarWrapper">
				<ul class="sidebarList">
					<li id="logout" className="sidebarListItem" onClick={handleLogout}>
						<IoIcons.IoPower className="sidebarIcon" />
						<span id="logout" className="sidebarListItemText">
							Logout
						</span>
					</li>
				</ul>
			</div>
			<div
				className="fixed-bottom"
				style={{
					backgroundColor: "#af6ffb",
					textAlign: "center",
					padding: "20px",
					color: "white",
				}}
			>
				Copyright © {currentYear} Healerverse
			</div>
		</div>
	);
}

export default Sidebar;
