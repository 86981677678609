import React from "react";
import { useSpring, animated } from "react-spring";
import { Container } from "react-bootstrap";
import * as AiIcons from "react-icons/ai";

import "./modal.css";
import AppointmentForm from "./AppointmentForm";

function Modal({ modalOpen, setOpenModal, isAppointment }) {
	const animation = useSpring({
		config: {
			duration: 4000,
		},
		opacity: modalOpen ? 1 : 0,
		transform: modalOpen ? `translateY(0%)` : `translateY(-100%)`,
	});

	return (
		<>
			{modalOpen ? (
				<Container fluid className="modalBackground">
					<animated.div style={animation}>
						<Container className="modalContainer">
							<div className="titleCloseBtn">
								<button
									onClick={() => {
										setOpenModal(false);
									}}
								>
									<AiIcons.AiOutlineCloseCircle />
								</button>
							</div>
							<div className="body">
								<AppointmentForm
									isAppointment={isAppointment}
									setOpenModal={setOpenModal}
								/>
							</div>
						</Container>
					</animated.div>
				</Container>
			) : null}
		</>
	);
}

export default Modal;
