import * as api from '../api';
import { CREATE, DELETE, FETCH_EVENTS, CHECKOUT } from '../constants/actionTypes';


export const createEvent = (value, user) => async (dispatch) => {
    try {
        const {data} = await api.createEvent({data: value, user});
        // console.log(data)
        dispatch({type: CREATE, payload: data})
    } catch (error) {
        console.log(error)
    }
}

export const createEventCheckout = (id, user) => async (dispatch) => {
    try {
        const {data} = await api.createEventCheckout({id, user});
        dispatch({type: CHECKOUT, payload: data})
        return data.id
    } catch (error) {
        console.log(error)
    }
}

export const fetchEvents = () => async (dispatch) => {
    try {
        const {data} = await api.fetchEvents();
        // console.log(data)
        dispatch({type: FETCH_EVENTS, payload: data})
        return data
    } catch (error) {
        console.log(error)
    }
}

export const fetchEventById = (id) => async (dispatch) => {
    try {
        const {data} = await api.fetchEventById(id);
        dispatch({type: FETCH_EVENTS, payload: data})
        return data
    } catch (error) {
        console.log(error)
    }
}