import React, { useState } from "react";
import * as IoIcons from "react-icons/io5";
import { Navbar, Container, Nav, Button, Row, Col } from "react-bootstrap";
import "./Navigation.css";
import { useDispatch } from "react-redux";
import { getPostsBySearch } from "../../actions/posts";
import { useEffect } from 'react'
import { getUser } from '../../api'
function ProfileNavigation() {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      // search post
      searchPost();
    }
  };
  const [userData, setUserData] = useState(null);
  const username = localStorage.getItem("username")

  useEffect(() => {
    const fetchUserData = async () => {
        try {
            const response = await getUser(username);
            console.log("response ", response);
            setUserData(response);
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };

    fetchUserData();
}, [username]);

  const searchPost =async () => {
    (search.trim())
		const data = await dispatch(getPostsBySearch({ search }))
    // console.log(data)
    if (data.posts.length > 0 || data.users.length > 0) {
      window.location.replace(`/search?searchQuery=${search || 'none'}`)
    } else {
      window.alert("No results found")
    }
  }

  return (
    <Container fluid>
      <Navbar
        collapseOnSelect
        className="fixed-top pt-4 pb-3"
        expand="lg"
        variant="dark"
        style={{ backgroundColor: "#242424" }}
      >
        <Container>
          <Navbar.Brand style={{ paddingRight: "25px" }} href="/">
            HEALERVERSE
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            className="justify-content-end"
            id="responsive-navbar-nav"
          >
            {/* <Nav style={{ textAlign: "left" }}>
              {ProfileNavigationData.map((item, index) => {
                return (
                  <Nav.Item key={index}>
                    <Nav.Link href={item.path}>{item.title}</Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav> */}
          </Navbar.Collapse>

          <Navbar.Collapse
            className="justify-content-end"
            id="responsive-navbar-nav"
          >
            <div className="searchbar">
              <IoIcons.IoSearch className="searchIcon" />
              <input
                placeholder="Search for a user, post or hashtag"
                className="searchInput"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  handleKeyPress(e);
                }} 
              />
            </div>
            <Nav>
              <Row>
                <Col>
                  <Button
                    className="nav-btn"
                    variant="outline"
                    style={{ fontSize: "20px" }}
					onClick={searchPost}
				  >
                    <IoIcons.IoSearch />
                  </Button>
                </Col>
                {/* <Col>
									<Nav.Item>
										<Button
											className="nav-btn"
											variant="outline"
											style={{ fontSize: "20px" }}
										>
											<IoIcons.IoNotifications />
										</Button>
									</Nav.Item>
								</Col> */}
                {/* <Col>
                  <Nav.Item>
                    <Button
                      className="nav-btn"
                      variant="outline"
                      style={{ fontSize: "20px" }}
                    >
                      <IoIcons.IoChatbubble />
                    </Button>
                  </Nav.Item>
                </Col> */}
                {/* <Col>
                  <Nav.Item>
                    <Button
                      className="nav-btn"
                      variant="outline"
                      style={{ fontSize: "20px" }}
                    >
                      <IoIcons.IoPeople />
                    </Button>
                  </Nav.Item>
                </Col> */}
                <Col>
                  <Nav.Item>
                    <Navbar.Brand href="/profile">
                    <img
                      className="img-fluid rounded-circle"
                      src={ !userData?.profileImage ? "https://via.placeholder.com/150" : userData.profileImage}
                      alt="profile"
                      style={{
                        width: "45px",
                        height: "45px",
                        backgroundSize: "cover",
                      }}
                    />
                    </Navbar.Brand>
                  </Nav.Item>
                </Col>
              </Row>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
}

export default ProfileNavigation;
