import React from "react";
import { Container } from "react-bootstrap";

function About() {
  return (
    <Container
      fluid
      className="about-section pb-5"
      style={{
        margin: "auto",
        textAlign: "center",
        width: "85vw",
        position: "relative",
        top: "100px",
        color: "white",
      }}
    >
      <h1>About Us</h1>
      <br />
      <br />
      <br />

      <h4>Our Mission</h4>

      <p className="home-text">
        To level up global collective consciousness by dissolving barriers with
        unity through healing and transformation of the mind, body, and spirit.
      </p>
      <br />
      <h4>Our Vision</h4>

      <p className="home-text">
        Heal yourself, Heal Others, Heal the World… Healerverse!
      </p>
      <br />
      <h4>Inclusivity Statement and Values</h4>

      <p className="home-text">
        We embrace everyone's journey being different and love you for exactly
        where you're at. We believe no one is on the outside. We're all one and
        embrace individuality. It takes a tribe to come together, to work
        together and to Heal The World.
      </p>

      <br />
      <h4>Values</h4>
      <p className="home-text">
        EVERYONE MATTERS, UNITY, WE EMBRACE DIFFERENCES, LOVE, SERVICE,
        DISCIPLINE, PERSEVERANCE, HONESTY, HOPE, FAITH, COURAGE, INTEGRITY,
        WILLINGNESS, & HUMILITY.
      </p>
      <br />

      <h4>Giving Back</h4>
      <p className="home-text">
        We are committed to giving back significant proceeds and making a
        difference in our local and global communities. If you have specific
        charities and concerns that you want Healerverse to contribute to,
        please email us at: givingback@5dhealerverse.com. Additionally, we
        invite all missions to join Healerverse and spread the message of your
        amazing missions!{" "}
      </p>
      <br />

      <h4>Web Accessibility Statement</h4>
      <p className="home-text">
        Healerverse is committed to providing accessibility to all people,
        regardless of technology or ability. We are actively taking steps to
        further improve the accessibility of Healerverse to ensure we provide
        equal access to all our users and we are actively taking steps to
        further enhance the accessibility of our website to meet or exceed
        applicable standards. We request our healers and organizations to
        provide subtitles with all content and any other available
        accessibility. We continue to strive toward our goal of promoting
        accessibility and we would welcome your feedback on how we might
        improve.
      </p>
      <br />

      <h4> Healerverse Metaverse</h4>
      <br />
      <p className="home-text">
        {" "}
        <em>Let’s do more in the Metaverse together.</em>{" "}
      </p>
      <br />
      <p className="home-text">
        Healerverse is committed to making the biggest global impact and leading
        the way with the healing community in the Metaverse! Healerverse in the
        Metaverse will revolutionize every aspect of Community, Connection,
        Unity, Creation, and Healing: Decentralization, Virtual Reality, Digital
        Art, Artificial Intelligence, Smart Contracts, and other Blockchain
        Technologies.{" "}
      </p>
    </Container>
  );
}

export default About;
