import React, { useState } from "react";
import "./editProfile.css";
import { updateUser } from "../../api";

function EditProfile() {
	const [formData, setFormData] = useState({});

	const handleChange = (event) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		await updateUser(formData);
		// console.log(formData);
		window.location.reload()
	};

	return (
		<div className="editProfile">
			<div class="editProfileWrapper">
				<h4 style={{ fontWeight: "bold" }}>Edit Profile</h4>
				<br />
				<form onSubmit={handleSubmit}>
					<div className="formbar">
						<input
							placeholder="First Name"
							type="text"
							name="firstName"
							onChange={handleChange}
							className="formInput"
						/>
					</div>

					<br />
					<div className="formbar">
						<input
							placeholder="Last Name"
							type="text"
							name="lastName"
							onChange={handleChange}
							className="formInput"
						/>
					</div>

					<br />
					<div className="formbar">
						<input
							placeholder="Email"
							type="email"
							name="email"
							onChange={handleChange}
							className="formInput"
						/>
					</div>

					<br />
					<div className="formbar">
						<select
							placeholder="Gender"
							name="gender"
							onChange={handleChange}
							className="formInput"
							style={{ width: "96%" ,color: "#af6ffb"  }}
						>
							<option value="male">Male</option>
							<option value="female">Female</option>
							<option value="nonbinary">Non-Binary</option>
							<option value="trans">Transgender</option>
						</select>
					</div>

					<br />
					<div className="formbar">
						<input
							placeholder="Country"
							type="text"
							name="country"
							onChange={handleChange}
							className="formInput"
						/>
					</div>

					<br />
					<div className="formbar">
						<input
							placeholder="City"
							type="text"
							name="city"
							onChange={handleChange}
							className="formInput"
						/>
					</div>

					<br />

					<textarea
						placeholder="Write Something In Your Bio ..."
						name="bio"
						onChange={handleChange}
						className="formTextArea"
					/>

					<br />
					<button type="submit" className="form-submit">
						Save Changes
					</button>
				</form>
			</div>
		</div>
	);
}

export default EditProfile;
