import React, { useState, useEffect } from "react";
import { payment } from "../../api/index";
import { Col, Container, Row, Button, Modal } from "react-bootstrap";
import {
	CardElement,
	useStripe
} from "@stripe/react-stripe-js";
import Checkbox from "@mui/material/Checkbox";
import "./checkout.css";
import decode from "jwt-decode";
import { loadStripe } from "@stripe/stripe-js";

export default function CheckoutForm() {
	const [success, setSuccess] = useState(false);
	const username = localStorage.getItem("username");

	useEffect(() => {
		const token = localStorage.getItem("token");
		if (!token) {
			window.location.replace("/login");
		} else {
			const decodedToken = decode(token)
			if (decodedToken.exp * 1000 < Date.now()) {
				localStorage.clear();
				window.location.replace("/login");
			}
		}

	}, []);

	const [monthly, setIsMonthly] = useState(false);
	const [yearly, setIsYearly] = useState(false);

	const [isModalOpen1, setIsModalOpen1] = useState(false);
	const [isModalOpen2, setIsModalOpen2] = useState(false);
	const handleToggleModal1 = () => setIsModalOpen1(!isModalOpen1);
	const handleToggleModal2 = () => setIsModalOpen2(!isModalOpen2);

	const handleCheckbox1Change = () => {
		setIsMonthly(true);
		setIsYearly(false);
	};

	const handleCheckbox2Change = () => {
		setIsYearly(true);
		setIsMonthly(false);
	};

	const stripe = useStripe();
	const stripePromise = loadStripe(
        "pk_live_51McJE2JGR0pSGyMUCT0ichUhHFr6SmOO96j3cGB04Kp8GMeoAwJtXxM8bA4CvxyazPTJ9mAylWDfgdKEOQMdybOu00HhQhE2Ue"
    );

	const handleSubmit = async (e) => {
		e.preventDefault();

		const isMonthly = monthly === true ? "true" : "false";
		/**
		 * @dev API response for payment processing
		*/
		try {
			// Fetch the Checkout Session from your backend
			const response = await payment(
				username,
				isMonthly
			);

			const sessionId = response.data.id.id;

			// Redirect the user to the Stripe Checkout page
			const stripe = await stripePromise;
			const { error } = await stripe.redirectToCheckout({
			  sessionId: sessionId,
			});
		
			// Handle any errors that occurred during the redirect
			if (error) {
			  console.error('Error redirecting to Stripe Checkout:', error);
			}
		  } catch (error) {
			console.error('Error fetching Checkout Session:', error);
		  }
	};

	return (
		<Container
			fluid
			style={{
				margin: "auto",
				textAlign: "center",
				width: "85vw",
				position: "relative",
				top: "100px",
			}}
		>
			<form>
				<div>
					<h2 style={{ color: "white", paddingBottom: "10px" }}>
						{success ? "Payment Successful" : "Make Payment"}
					</h2>
				</div>

				<br />

				<div
					style={{
						textAlign: "centre",
						border: "none",
						color: "white",

						margin: "auto",
						marginBottom: "50px",
					}}
					className="resWidth"
				>
					<Row className="resWidth">
							<Checkbox
								checked={monthly}
								onChange={handleCheckbox1Change}
								style={{ color: "white" }}
							/>
							<p
								style={{
									fontSize: "10px",
									margin: "auto",
									textAlign: "center",
								}}
							>
								Pay $88 Monthly
							</p>
					</Row>
					<Row className="resWidth">
							<Checkbox
								checked={yearly}
								onChange={handleCheckbox2Change}
								style={{ color: "white" }}
							/>
							<p
								style={{
									fontSize: "10px",
									margin: "auto",
									textAlign: "center",
								}}
							>
								Pay $888 Yearly
							</p>
					</Row>
				</div>

				<Button className="home-btn" id="btn-purple" onClick={handleSubmit}>
					Pay
				</Button>
			</form>
			<Modal
				show={isModalOpen1}
				onHide={handleToggleModal1}
				animation={true}
				centered
			>
				<Modal.Body
					style={{
						backgroundColor: "#454545",
						color: "white",
					}}
				>
					<h2>Payment Failed</h2>
					<p>Please check your details and try again.</p>
				</Modal.Body>
			</Modal>
			<Modal
				show={isModalOpen2}
				onHide={handleToggleModal2}
				animation={true}
				centered
			>
				<Modal.Body
					style={{
						backgroundColor: "#454545",
						color: "white",
					}}
				>
					<h2>Payment Failed</h2>
					<p>Username was not found.</p>
				</Modal.Body>
			</Modal>
		</Container>
	);
}
